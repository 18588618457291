import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

const LmcUserStartPage = ({
  setStartPage,
}: {
  setStartPage: (value: boolean) => void;
}) => {
  const { t } = useTranslation();

  const timerSetter = useCallback(() => {
    setTimeout(() => {
      setStartPage(false);
    }, 2000);
  }, [setStartPage]);

  useEffect(() => {
    timerSetter();
  }, [timerSetter]);

  return (
    <div className="w-full md:w-[400px] mx-auto h-screen absolute pb-8 pt-6 top-0 left-0 bg-gray-800 rounded-[32px] flex flex-col justify-between items-center">
      <div className="w-full flex justify-center items-center gap-4">
        <div className="h-8 ">
          <img
            src="https://cdn.pinkswan.ch/application/UMD/24042216091381_Logo.svg"
            alt="SUM"
          />
        </div>
      </div>
      <h1 className="text-sky-300 text-5xl font-extrabold font-['Figtree'] leading-[48px] text-center">
        {/* Your
        <br /> */}
        {t("lmcUser.key10")}
        <br />
        {t("lmcUser.key11")}
      </h1>
      <h3
        className="text-gray-500 text-lg font-bold font-['Figtree'] leading-7 cursor-pointer"
        onClick={() => setStartPage(false)}
      >
        {t("lmcUser.key12")}
      </h3>
    </div>
  );
};

export default LmcUserStartPage;
