import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import React from "react";

const NotFound = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Super User Manager - Page not found</title>
        <meta name="description" content="Your page is not found" />
        <meta name="robots" content="noindex" />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_HOST_URL}/not-found`}
        />
      </Helmet>
      <div className="h-full bg-white flex-col justify-start items-center inline-flex">
        <div className=" px-8 pt-6 justify-center items-center gap-4 inline-flex">
          <div className=" px-4 flex-col justify-start items-center inline-flex">
            <img
              src="https://cdn.pinkswan.ch/application/UMD/2405110558455_logo.png"
              alt="SUM"
              className="w-[40px] h-[40px]"
            />
          </div>
        </div>
        <div className="w-full grow shrink basis-0 px-8 py-[52px] flex-col justify-center items-center flex">
          <div className="grid grid-cols-12 self-stretch grow shrink basis-0 justify-center items-center">
            <div className="md:col-span-6 col-span-12 h-full md:order-1 order-2 grow shrink basis-0 self-stretch px-4 py-16 bg-stone-50 flex-col justify-center items-center gap-6 inline-flex">
              <img
                className="w-[503px] h-[323px]"
                src="https://cdn.pinkswan.ch/application/UMD/23030509171580_UMD_Error404.svg"
                alt="not found"
              />
            </div>
            <div className="md:col-span-6 col-span-12 h-full md:order-2 order-1 grow shrink basis-0 self-stretch px-4 py-16 flex-col justify-center items-center gap-6 inline-flex">
              <div className="self-stretch flex-col justify-start items-center gap-2 flex">
                <div className="self-stretch text-center text-sky-600 text-sm font-semibold font-['Inter'] uppercase leading-tight tracking-tight">
                  404 error
                </div>
                <div className="self-stretch text-center text-gray-900 text-5xl font-extrabold font-['Inter'] leading-[48px]">
                  Page not found.
                </div>
                <div className="self-stretch text-center text-gray-500 text-base font-normal font-['Inter'] leading-normal">
                  Sorry, we couldn’t find the page you’re looking for.
                </div>
              </div>
              <div className="justify-start items-center inline-flex">
                <Link
                  to="/"
                  className="text-sky-600 text-base font-medium font-['Inter'] leading-normal"
                >
                  Go back home →
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NotFound;
