import { NavLink } from 'react-router-dom'

const AuthLogoRedirect = ({ description }: { description: string }) => {
    return (
        <div className="underlined-off text-center">
            <div className="brand d-flex flex-column align-items-center">
                {/* <h2 className="mb-0">{Login.brand.title}</h2> */}
                <NavLink to={'/'} className='d-inline-block'>
                    <img title="Super User Manager" loading="lazy" src="https://cdn.pinkswan.ch/application/UMD/22102616222155_SUM_Logo_1.svg" alt="Super User Manager" width={40} height={40} className="img-fluid mb-2" />
                </NavLink>
                <small className="mb-0">
                    {description}
                </small>
            </div>
        </div>

    )
}

export default AuthLogoRedirect