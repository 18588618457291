import React, { useCallback, useMemo, useState } from "react";
import { AxiosError, AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import useAuth from "../../../../hooks/useAuth";
import HttpService from "../../../../services/http";
import ResultLmcCard from "./ResultLmcCard";
import { Button, Modal, Spinner } from "flowbite-react";
import {
  HiBadgeCheck,
  HiCalendar,
  HiChevronDown,
  HiChevronRight,
  HiClipboardCheck,
  HiClock,
  HiCurrencyDollar,
  HiPlus,
  HiReceiptTax,
  HiStar,
} from "react-icons/hi";
import { INormalizedLmcDataType, lmcOptionType } from "./types";
import PointCard, { PointCardUi } from "./PointCard";
import HistoryMessageCard, { HistoryMessageCardUi } from "./HistoryMessageCard";
import PercentageCard, { PercentageCardUi } from "./PercentageCard";
import IncrementalCard, { IncrementalCardUi } from "./IncrementalCard";
import AmountCard, { AmountCardUi } from "./AmountCard";
import DeadlineCard, { DeadlineCardUi } from "./DeadlineCard";
import VolumeCard, { VolumeCardUi } from "./VolumeCard";
import { Helmet } from "react-helmet-async";

export type optionsType = {
  label: string;
  value:
    | "pointCard"
    | "historyMessageCard"
    | "percentageCard"
    | "incrementalCard"
    | "amountCard"
    | "deadlineCard"
    | "volumeCard"
    | null;
  image: string;
  icon: React.ReactNode;
  description: string;
};

const LmcPage = () => {
  const { t, i18n } = useTranslation();
  const { user, customer_identification } = useAuth();
  const queryClient = useQueryClient();

  const lmcAvailableTypes: optionsType[] = [
    {
      label: t("dashboard.features.lmc.page.types.01"),
      value: "pointCard",
      image:
        "https://cdn.pinkswan.ch/application/UMD/23051508511566_Point_card.svg",
      icon: <HiBadgeCheck size={22} className="text-sky-500 mb-2" />,
      description: t("dashboard.features.lmc.pointCard.alertDesc"),
    },
    {
      label: t("dashboard.features.lmc.page.types.02"),
      value: "historyMessageCard",
      image:
        "https://cdn.pinkswan.ch/application/UMD/2305150851154_History_message_card.svg",
      icon: <HiCalendar size={22} className="text-sky-500 mb-2" />,
      description: t("dashboard.features.lmc.historyMessageCard.alertDesc"),
    },
    {
      label: t("dashboard.features.lmc.page.types.03"),
      value: "incrementalCard",
      image:
        "https://cdn.pinkswan.ch/application/UMD/23051508511567_Cashback_card.svg",
      icon: <HiClipboardCheck size={22} className="text-sky-500 mb-2" />,
      description: t("dashboard.features.lmc.incrementalCard.alertDesc"),
    },
    {
      label: t("dashboard.features.lmc.page.types.04"),
      value: "percentageCard",
      image:
        "https://cdn.pinkswan.ch/application/UMD/23051508511587_Step-up_card.svg",
      icon: <HiReceiptTax size={22} className="text-sky-500 mb-2" />,
      description: t("dashboard.features.lmc.percentageCard.alertDesc"),
    },
    {
      label: t("dashboard.features.lmc.page.types.05"),
      value: "amountCard",
      image:
        "https://cdn.pinkswan.ch/application/UMD/23051508511535_Spending_card.svg",
      icon: <HiCurrencyDollar size={22} className="text-sky-500 mb-2" />,
      description: t("dashboard.features.lmc.amountCard.alertDesc"),
    },
    {
      label: t("dashboard.features.lmc.page.types.06"),
      value: "deadlineCard",
      image:
        "https://cdn.pinkswan.ch/application/UMD/23051508511561_Time_limit_card.svg",
      icon: <HiClock size={22} className="text-sky-500 mb-2" />,
      description: t("dashboard.features.lmc.deadlineCard.alertDesc"),
    },
    {
      label: t("dashboard.features.lmc.page.types.07"),
      value: "volumeCard",
      image:
        "https://cdn.pinkswan.ch/application/UMD/23051508511553_Quantity_card.svg",
      icon: <HiStar size={22} className="text-sky-500 mb-2" />,
      description: t("dashboard.features.lmc.volumeCard.alertDesc"),
    },
  ];

  const lmcLabelMapper = useMemo(() => {
    return {
      POINT_CARD: "pointCard",
      HIST_MESSAGE_CARD: "historyMessageCard",
      PERCENTAGE_CARD: "percentageCard",
      INCREMENTAL_CARD: "incrementalCard",
      AMOUNT_CARD: "amountCard",
      DEADLINE_CARD: "deadlineCard",
      VOLUME_CARD: "volumeCard",
    };
  }, []);

  const lmcLabelMapperReverse = {
    pointCard: "POINT_CARD",
    historyMessageCard: "HIST_MESSAGE_CARD",
    percentageCard: "PERCENTAGE_CARD",
    incrementalCard: "INCREMENTAL_CARD",
    amountCard: "AMOUNT_CARD",
    deadlineCard: "DEADLINE_CARD",
    volumeCard: "VOLUME_CARD",
  };

  const [isInitiating, setIsInitiating] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedLmcForCreation, setSelectedLmcForCreation] =
    useState<optionsType["value"]>();
  const [hasLmc, setHasLmc] = useState<boolean>();
  const [currentPlaygroundData, setCurrentPlaygroundData] = useState<{
    type: lmcOptionType;
    data: INormalizedLmcDataType;
  }>();
  const [processId, setProcessId] = useState("");
  const [editMode, setEditMode] = useState(false);

  const fetchLmcProcessData = useMutation(
    () =>
      HttpService.get(
        `/${customer_identification}/${user.entityIdentification}/entity/process/lmc/identification`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess(data, variables, context) {
        setProcessId(data.data.element.process_identification);
      },
    }
  );

  const {
    data: res,
    isError,
    isLoading,
    isSuccess,
    refetch,
  } = useQuery(
    ["entity_preferences", user.entityIdentification],
    () =>
      HttpService.get(
        `/${customer_identification}/${user.entityIdentification}/entity/preferences`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess(data) {
        if (data.data.element.lmc_form) {
          setHasLmc(true);

          fetchLmcProcessData.mutate();

          setSelectedLmcForCreation(
            lmcLabelMapper[
              data.data.element.lmc_form.lmc_type as lmcOptionType
            ] as optionsType["value"]
          );

          setCurrentPlaygroundData({
            type: data.data.element.lmc_form.lmc_type as lmcOptionType,
            data: data.data.element.lmc_form,
          });

          setIsInitiating(false);
        } else {
          setHasLmc(false);

          setIsInitiating(false);
        }
      },
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);

        setIsInitiating(false);
      },
    }
  );

  const UpdateEntityPreferences = useMutation<any, AxiosError<any, any>, any>(
    (data) =>
      HttpService.patch(`/entity/${user.entityIdentification}/lmc`, data, {
        auth: HttpService.getToken(),
      })
  );

  const handleModifyLmcInEntityPreferences = useCallback(
    async (type: lmcOptionType, data: INormalizedLmcDataType) => {
      return new Promise<void>((resolve, reject) => {
        const { lmc_form } = res?.data.element;

        UpdateEntityPreferences.mutate(
          {
            ...data,
            login_form: lmc_form?.login_form || {},
            language: i18n.language,
          },
          {
            onSuccess: (res: AxiosResponse<any, any>) => {
              queryClient.invalidateQueries([
                "entity_preferences",
                user.entityIdentification,
              ]);

              resolve();

              // if (res.data.element.processId)
              //   setProcessId(res.data.element.processId);

              // if (res.data.element.lmcData) {
              //   setCurrentPlaygroundData({
              //     type: res.data.element.lmcData.process_data
              //       .lmc_type as lmcOptionType,
              //     data: res.data.element.lmcData.process_data,
              //   });
              // }

              toast.success(res.data.message);

              setEditMode(false);
            },
            onError: (err: any) => {
              reject();

              toast.error(err.response?.data.message || err.message);
            },
          }
        );
      });
    },
    [
      UpdateEntityPreferences,
      i18n.language,
      queryClient,
      res?.data.element,
      user.entityIdentification,
    ]
  );

  const handleCancelEditMode = useCallback(() => {
    if (isSuccess && res?.data?.element?.lmc_form) {
      setHasLmc(true);

      setSelectedLmcForCreation(
        lmcLabelMapper[
          res?.data?.element?.lmc_form?.lmc_type as lmcOptionType
        ] as optionsType["value"]
      );

      setCurrentPlaygroundData({
        type: res?.data?.element?.lmc_form?.lmc_type as lmcOptionType,
        data: res?.data?.element?.lmc_form,
      });
    } else {
      setHasLmc(false);

      setSelectedLmcForCreation(undefined);

      setCurrentPlaygroundData(undefined);
    }

    setEditMode(false);
  }, [isSuccess, lmcLabelMapper, res?.data?.element.lmc_form]);

  const renderProperForm = useCallback(() => {
    switch (selectedLmcForCreation) {
      case "pointCard":
        return (
          <PointCard
            onSubmitCardData={handleModifyLmcInEntityPreferences}
            optionData={
              res?.data?.element?.lmc_form?.lmc_type === "POINT_CARD"
                ? res?.data?.element?.lmc_form
                : null
            }
            playgroundDataHandler={setCurrentPlaygroundData}
            handleCancelEdit={handleCancelEditMode}
          />
        );
      case "historyMessageCard":
        return (
          <HistoryMessageCard
            onSubmitCardData={handleModifyLmcInEntityPreferences}
            optionData={
              res?.data?.element?.lmc_form?.lmc_type === "HIST_MESSAGE_CARD"
                ? res?.data?.element?.lmc_form
                : null
            }
            playgroundDataHandler={setCurrentPlaygroundData}
            handleCancelEdit={handleCancelEditMode}
          />
        );
      case "percentageCard":
        return (
          <PercentageCard
            onSubmitCardData={handleModifyLmcInEntityPreferences}
            optionData={
              res?.data?.element?.lmc_form?.lmc_type === "PERCENTAGE_CARD"
                ? res?.data?.element?.lmc_form
                : null
            }
            playgroundDataHandler={setCurrentPlaygroundData}
            handleCancelEdit={handleCancelEditMode}
          />
        );
      case "incrementalCard":
        return (
          <IncrementalCard
            onSubmitCardData={handleModifyLmcInEntityPreferences}
            optionData={
              res?.data?.element?.lmc_form?.lmc_type === "INCREMENTAL_CARD"
                ? res?.data?.element?.lmc_form
                : null
            }
            playgroundDataHandler={setCurrentPlaygroundData}
            handleCancelEdit={handleCancelEditMode}
          />
        );
      case "amountCard":
        return (
          <AmountCard
            onSubmitCardData={handleModifyLmcInEntityPreferences}
            optionData={
              res?.data?.element?.lmc_form?.lmc_type === "AMOUNT_CARD"
                ? res?.data?.element?.lmc_form
                : null
            }
            playgroundDataHandler={setCurrentPlaygroundData}
            handleCancelEdit={handleCancelEditMode}
          />
        );
      case "deadlineCard":
        return (
          <DeadlineCard
            onSubmitCardData={handleModifyLmcInEntityPreferences}
            optionData={
              res?.data?.element?.lmc_form?.lmc_type === "DEADLINE_CARD"
                ? res?.data?.element?.lmc_form
                : null
            }
            playgroundDataHandler={setCurrentPlaygroundData}
            handleCancelEdit={handleCancelEditMode}
          />
        );
      case "volumeCard":
        return (
          <VolumeCard
            onSubmitCardData={handleModifyLmcInEntityPreferences}
            optionData={
              res?.data?.element?.lmc_form?.lmc_type === "VOLUME_CARD"
                ? res?.data?.element?.lmc_form
                : null
            }
            playgroundDataHandler={setCurrentPlaygroundData}
            handleCancelEdit={handleCancelEditMode}
          />
        );
      default:
        return null;
    }
  }, [
    selectedLmcForCreation,
    handleModifyLmcInEntityPreferences,
    res?.data?.element?.lmc_form,
    handleCancelEditMode,
  ]);

  const renderProperUi = {
    pointCard:
      selectedLmcForCreation && currentPlaygroundData ? (
        <PointCardUi
          key={"pointCard"}
          data={
            currentPlaygroundData.type ===
            lmcLabelMapperReverse[selectedLmcForCreation]
              ? currentPlaygroundData.data
              : null
          }
        />
      ) : null,
    historyMessageCard:
      selectedLmcForCreation && currentPlaygroundData ? (
        <HistoryMessageCardUi
          key={"historyMessageCard"}
          data={
            currentPlaygroundData.type ===
            lmcLabelMapperReverse[selectedLmcForCreation]
              ? currentPlaygroundData.data
              : null
          }
        />
      ) : null,
    percentageCard:
      selectedLmcForCreation && currentPlaygroundData ? (
        <PercentageCardUi
          key={"percentageCard"}
          data={
            currentPlaygroundData.type ===
            lmcLabelMapperReverse[selectedLmcForCreation]
              ? currentPlaygroundData.data
              : null
          }
        />
      ) : null,
    incrementalCard:
      selectedLmcForCreation && currentPlaygroundData ? (
        <IncrementalCardUi
          key={"incrementalCard"}
          data={
            currentPlaygroundData.type ===
            lmcLabelMapperReverse[selectedLmcForCreation]
              ? currentPlaygroundData.data
              : null
          }
        />
      ) : null,
    amountCard:
      selectedLmcForCreation && currentPlaygroundData ? (
        <AmountCardUi
          key={"amountCard"}
          data={
            currentPlaygroundData.type ===
            lmcLabelMapperReverse[selectedLmcForCreation]
              ? currentPlaygroundData.data
              : null
          }
        />
      ) : null,
    deadlineCard:
      selectedLmcForCreation && currentPlaygroundData ? (
        <DeadlineCardUi
          key={"deadlineCard"}
          data={
            currentPlaygroundData.type ===
            lmcLabelMapperReverse[selectedLmcForCreation]
              ? currentPlaygroundData.data
              : null
          }
        />
      ) : null,
    volumeCard:
      selectedLmcForCreation && currentPlaygroundData ? (
        <VolumeCardUi
          key={"volumeCard"}
          data={
            currentPlaygroundData.type ===
            lmcLabelMapperReverse[selectedLmcForCreation]
              ? currentPlaygroundData.data
              : null
          }
        />
      ) : null,
  };

  let content;

  if (isLoading || isInitiating)
    content = (
      <div className="mt-4">
        <Spinner size="sm" />
      </div>
    );

  if (isError)
    content = (
      <div className="mt-4">
        <Button color="light" onClick={() => refetch()}>
          Reload
        </Button>
      </div>
    );

  if (isSuccess)
    content = (
      <div className="grid grid-cols-12 h-full">
        <div className="lg:col-span-4 md:col-span-5 col-span-12 h-full">
          <div className="bg-white w-full h-full p-6 flex-col justify-start items-start gap-6 inline-flex">
            <div className="self-stretch text-gray-700 text-2xl font-bold font-['Figtree'] leading-none">
              {t("dashboard.features.lmc.page.key1")}
            </div>

            {!isInitiating && !hasLmc && !selectedLmcForCreation && (
              <React.Fragment>
                <div className="self-stretch flex-col justify-start items-start flex">
                  <div className="self-stretch text-gray-900 text-sm font-normal font-['Figtree'] leading-tight">
                    {t("dashboard.features.lmc.page.key2")}
                  </div>
                </div>
                <button
                  onClick={() => setShowModal(true)}
                  className="self-stretch pl-[11px] pr-[13px] py-[9px] bg-sky-500 rounded-md shadow justify-center items-center gap-2 inline-flex"
                >
                  <div className="w-5 h-5 relative">
                    <HiPlus size={18} color="white" />
                  </div>
                  <div className="text-white text-sm font-semibold font-['Inter'] leading-none">
                    {t("dashboard.features.lmc.page.key3")}
                  </div>
                </button>
                <div className="self-stretch h-px bg-gray-300" />
              </React.Fragment>
            )}

            {!isInitiating && selectedLmcForCreation && (
              <React.Fragment>
                {/* Chooser */}
                <div className="w-full flex-col justify-start items-start gap-2 inline-flex">
                  <div className="text-zinc-500 text-xs font-normal font-['Figtree'] leading-none">
                    {t("dashboard.features.lmc.page.key4")}
                  </div>

                  {editMode ? (
                    <div
                      onClick={() => setShowModal(true)}
                      className="self-stretch justify-start items-start gap-5 inline-flex cursor-pointer"
                    >
                      <div className="grow shrink basis-0 pl-[17px] pr-[13px] py-[9px] bg-white rounded-md shadow border border-gray-300 justify-between items-center flex">
                        <div className="text-gray-700 text-sm font-medium font-['Inter'] leading-tight">
                          {
                            lmcAvailableTypes.find(
                              (item) => item.value === selectedLmcForCreation
                            )?.label
                          }
                        </div>
                        <div className="w-5 h-5 relative">
                          <HiChevronDown className="text-gray-300" size={20} />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="self-stretch justify-start items-start gap-5 inline-flex">
                      <div className="grow shrink basis-0 pl-[17px] pr-[13px] py-[9px] bg-white rounded-md shadow border border-gray-300 justify-between items-center flex">
                        <div className="text-gray-700 text-sm font-medium font-['Inter'] leading-tight">
                          {
                            lmcAvailableTypes.find(
                              (item) => item.value === selectedLmcForCreation
                            )?.label
                          }
                        </div>
                        <div
                          className="relative cursor-pointer text-sky-500"
                          onClick={() => setEditMode(true)}
                        >
                          {t("dashboard.features.lmc.page.key5")}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="self-stretch h-px bg-gray-300 block mt-4" />
                </div>

                {editMode ? (
                  <React.Fragment>
                    {/* Description of selected lmc */}
                    <div className="flex flex-col gap-2">
                      <div className="text-gray-700 text-xl font-bold font-['Figtree'] leading-7">
                        {
                          lmcAvailableTypes.find(
                            (item) => item.value === selectedLmcForCreation
                          )?.label
                        }
                      </div>
                      <div className="text-gray-900 text-sm font-normal font-['Figtree'] leading-tight">
                        {
                          lmcAvailableTypes.find(
                            (item) => item.value === selectedLmcForCreation
                          )?.description
                        }
                      </div>
                    </div>

                    {renderProperForm()}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {fetchLmcProcessData.isLoading ? (
                      <Spinner />
                    ) : fetchLmcProcessData.isError ? (
                      <Button
                        color={"light"}
                        onClick={() => fetchLmcProcessData.mutate()}
                      >
                        {t("dashboard.features.lmc.page.key10")}
                      </Button>
                    ) : (
                      <ResultLmcCard
                        lmc={
                          res?.data?.element?.lmc_form
                            ?.lmc_type as lmcOptionType
                        }
                        preferencesData={res?.data?.element || {}}
                        processId={processId}
                      />
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}

            <div className="app-download mt-auto w-full">
              <div className="text-center text-gray-700 text-lg font-bold font-['Figtree'] leading-7">
                {t("dashboard.features.lmc.page.key11")}
              </div>

              <ul className="flex items-center justify-around flex-wrap">
                <li className="me-sm-1 me-0 mb-sm-0 mb-1">
                  <a
                    href="https://apps.apple.com/us/app/loyalty-programs/id6483860761"
                    target="_blank"
                    aria-label="Apple store - LMC app"
                    rel="noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="167"
                      height="48"
                      viewBox="0 0 167 48"
                      fill="none"
                      style={{ width: "130px" }}
                    >
                      <path
                        d="M165.231 42.4645C165.231 45.0224 163.115 47.0944 160.499 47.0944H5.66048C3.04547 47.0944 0.922852 45.0224 0.922852 42.4645V5.5418C0.922852 2.98503 3.04547 0.905762 5.66048 0.905762H160.498C163.115 0.905762 165.229 2.98503 165.229 5.5418L165.231 42.4645Z"
                        fill="black"
                      />
                      <path
                        d="M160 0.961503C162.849 0.961503 165.168 3.222 165.168 6V42C165.168 44.778 162.849 47.0385 160 47.0385H6.15385C3.30462 47.0385 0.986153 44.778 0.986153 42V6C0.986153 3.222 3.30462 0.961503 6.15385 0.961503H160ZM160 2.53677e-06H6.15385C2.77077 2.53677e-06 0 2.7015 0 6V42C0 45.2985 2.77077 48 6.15385 48H160C163.383 48 166.154 45.2985 166.154 42V6C166.154 2.7015 163.383 2.53677e-06 160 2.53677e-06Z"
                        fill="#A6A6A6"
                      />
                      <path
                        d="M37.0809 23.7408C37.0452 19.8732 40.3289 17.9916 40.479 17.904C38.6193 15.2604 35.7369 14.8992 34.724 14.8704C32.303 14.622 29.9547 16.2828 28.7215 16.2828C27.4636 16.2828 25.5646 14.8944 23.5178 14.9352C20.884 14.9748 18.42 16.4616 17.0686 18.7704C14.2796 23.478 16.3596 30.396 19.0316 34.2012C20.3683 36.0648 21.9301 38.1456 23.9744 38.0724C25.9744 37.992 26.7215 36.8292 29.135 36.8292C31.5264 36.8292 32.228 38.0724 34.3129 38.0256C36.4593 37.992 37.8107 36.1536 39.1006 34.2732C40.6452 32.1372 41.2655 30.0336 41.2901 29.9256C41.2396 29.9088 37.1215 28.3764 37.0809 23.7408Z"
                        fill="white"
                      />
                      <path
                        d="M33.1424 12.3672C34.2181 11.0556 34.9541 9.27125 34.7498 7.46045C33.1929 7.52765 31.2458 8.51045 30.1246 9.79325C29.1326 10.9236 28.2464 12.7764 28.4753 14.5188C30.2243 14.646 32.02 13.6584 33.1424 12.3672Z"
                        fill="white"
                      />
                      <path
                        d="M66.025 37.8047H63.2299L61.6988 33.1139H56.377L54.9185 37.8047H52.1973L57.4699 21.8351H60.7265L66.025 37.8047ZM61.2373 31.1459L59.8526 26.9759C59.7062 26.5499 59.4317 25.5467 59.0268 23.9675H58.9776C58.8163 24.6467 58.5566 25.6499 58.1997 26.9759L56.8397 31.1459H61.2373Z"
                        fill="white"
                      />
                      <path
                        d="M79.5845 31.9056C79.5845 33.864 79.0417 35.412 77.9562 36.5484C76.9839 37.56 75.7765 38.0652 74.3352 38.0652C72.7796 38.0652 71.662 37.5204 70.9814 36.4308H70.9322V42.4968H68.3082V30.0804C68.3082 28.8492 68.2749 27.5856 68.2109 26.2896H70.5186L70.6651 28.1148H70.7143C71.5894 26.7396 72.9174 26.0532 74.6996 26.0532C76.0928 26.0532 77.2559 26.5896 78.1863 27.6636C79.1192 28.7388 79.5845 30.1524 79.5845 31.9056ZM76.9112 31.9992C76.9112 30.8784 76.6528 29.9544 76.1334 29.2272C75.566 28.4688 74.8042 28.0896 73.8491 28.0896C73.2017 28.0896 72.6134 28.3008 72.0879 28.7172C71.5611 29.1372 71.2165 29.6856 71.0552 30.3648C70.974 30.6816 70.9334 30.9408 70.9334 31.1448V33.0648C70.9334 33.9024 71.1968 34.6092 71.7236 35.1864C72.2503 35.7636 72.9346 36.0516 73.7765 36.0516C74.7648 36.0516 75.534 35.6796 76.0842 34.938C76.6356 34.1952 76.9112 33.216 76.9112 31.9992Z"
                        fill="white"
                      />
                      <path
                        d="M93.1682 31.9056C93.1682 33.864 92.6254 35.412 91.5387 36.5484C90.5676 37.56 89.3602 38.0652 87.919 38.0652C86.3633 38.0652 85.2457 37.5204 84.5664 36.4308H84.5171V42.4968H81.8931V30.0804C81.8931 28.8492 81.8599 27.5856 81.7959 26.2896H84.1036L84.2501 28.1148H84.2993C85.1731 26.7396 86.5011 26.0532 88.2845 26.0532C89.6765 26.0532 90.8396 26.5896 91.7725 27.6636C92.7018 28.7388 93.1682 30.1524 93.1682 31.9056ZM90.495 31.9992C90.495 30.8784 90.2353 29.9544 89.7159 29.2272C89.1485 28.4688 88.3891 28.0896 87.4328 28.0896C86.7842 28.0896 86.1971 28.3008 85.6704 28.7172C85.1436 29.1372 84.8002 29.6856 84.639 30.3648C84.559 30.6816 84.5171 30.9408 84.5171 31.1448V33.0648C84.5171 33.9024 84.7805 34.6092 85.3048 35.1864C85.8316 35.7624 86.5159 36.0516 87.3602 36.0516C88.3485 36.0516 89.1178 35.6796 89.6679 34.938C90.2193 34.1952 90.495 33.216 90.495 31.9992Z"
                        fill="white"
                      />
                      <path
                        d="M108.357 33.3264C108.357 34.6848 107.873 35.79 106.902 36.6432C105.835 37.5756 104.349 38.0412 102.44 38.0412C100.678 38.0412 99.2649 37.71 98.1953 37.0464L98.8033 34.914C99.9553 35.5932 101.219 35.934 102.597 35.934C103.585 35.934 104.354 35.7156 104.907 35.2812C105.457 34.8468 105.731 34.2636 105.731 33.5364C105.731 32.8884 105.505 32.3424 105.051 31.8996C104.599 31.4568 103.845 31.0452 102.791 30.6648C99.9233 29.622 98.4907 28.0944 98.4907 26.0856C98.4907 24.7728 98.9929 23.6964 99.9984 22.8588C101 22.02 102.337 21.6012 104.008 21.6012C105.499 21.6012 106.737 21.8544 107.725 22.3596L107.069 24.4452C106.146 23.9556 105.102 23.7108 103.934 23.7108C103.011 23.7108 102.29 23.9328 101.773 24.3744C101.336 24.7692 101.117 25.2504 101.117 25.8204C101.117 26.4516 101.367 26.9736 101.869 27.384C102.306 27.7632 103.1 28.1736 104.252 28.6164C105.661 29.1696 106.696 29.8164 107.362 30.558C108.025 31.2972 108.357 32.2224 108.357 33.3264Z"
                        fill="white"
                      />
                      <path
                        d="M117.031 28.2095H114.139V33.8003C114.139 35.2223 114.649 35.9327 115.67 35.9327C116.139 35.9327 116.528 35.8931 116.836 35.8139L116.908 37.7567C116.391 37.9451 115.711 38.0399 114.868 38.0399C113.831 38.0399 113.022 37.7315 112.437 37.1159C111.855 36.4991 111.562 35.4647 111.562 34.0115V28.2071H109.839V26.2871H111.562V24.1787L114.139 23.4203V26.2871H117.031V28.2095Z"
                        fill="white"
                      />
                      <path
                        d="M130.082 31.9524C130.082 33.7224 129.562 35.1756 128.526 36.312C127.439 37.482 125.997 38.0652 124.199 38.0652C122.466 38.0652 121.086 37.5048 120.057 36.384C119.028 35.2632 118.514 33.8484 118.514 32.1432C118.514 30.3588 119.043 28.8972 120.105 27.7608C121.165 26.6232 122.595 26.0544 124.393 26.0544C126.126 26.0544 127.52 26.6148 128.573 27.7368C129.58 28.8252 130.082 30.2304 130.082 31.9524ZM127.359 32.0352C127.359 30.9732 127.127 30.0624 126.655 29.3028C126.105 28.3836 125.319 27.9252 124.3 27.9252C123.245 27.9252 122.444 28.3848 121.893 29.3028C121.422 30.0636 121.189 30.9888 121.189 32.0832C121.189 33.1452 121.422 34.056 121.893 34.8144C122.461 35.7336 123.253 36.192 124.276 36.192C125.278 36.192 126.064 35.724 126.632 34.7904C127.116 34.0164 127.359 33.096 127.359 32.0352Z"
                        fill="white"
                      />
                      <path
                        d="M138.611 28.5397C138.351 28.4929 138.074 28.4689 137.784 28.4689C136.861 28.4689 136.147 28.8085 135.645 29.4889C135.208 30.0889 134.989 30.8473 134.989 31.7629V37.8049H132.366L132.39 29.9161C132.39 28.5889 132.357 27.3805 132.292 26.2909H134.578L134.674 28.4941H134.746C135.023 27.7369 135.46 27.1273 136.058 26.6701C136.643 26.2585 137.274 26.0533 137.955 26.0533C138.197 26.0533 138.416 26.0701 138.611 26.1001V28.5397Z"
                        fill="white"
                      />
                      <path
                        d="M150.346 31.5024C150.346 31.9608 150.315 32.3472 150.25 32.6628H142.378C142.409 33.8004 142.789 34.6704 143.52 35.2704C144.184 35.8068 145.041 36.0756 146.095 36.0756C147.261 36.0756 148.324 35.8944 149.28 35.5308L149.691 37.3068C148.574 37.782 147.254 38.0184 145.732 38.0184C143.901 38.0184 142.463 37.4928 141.417 36.4428C140.373 35.3928 139.85 33.9828 139.85 32.214C139.85 30.4776 140.336 29.0316 141.31 27.8784C142.329 26.6472 143.706 26.0316 145.439 26.0316C147.141 26.0316 148.43 26.6472 149.305 27.8784C149.998 28.8564 150.346 30.066 150.346 31.5024ZM147.844 30.8388C147.861 30.0804 147.69 29.4252 147.334 28.872C146.88 28.1604 146.182 27.8052 145.243 27.8052C144.385 27.8052 143.688 28.152 143.155 28.848C142.718 29.4012 142.458 30.0648 142.378 30.8376H147.844V30.8388Z"
                        fill="white"
                      />
                      <path
                        d="M60.37 12.0109C60.37 13.4233 59.9356 14.4865 59.0679 15.2005C58.2642 15.8593 57.122 16.1893 55.6426 16.1893C54.9091 16.1893 54.2814 16.1581 53.7559 16.0957V8.37853C54.4414 8.27053 55.1799 8.21533 55.9774 8.21533C57.3866 8.21533 58.4488 8.51413 59.1651 9.11173C59.9676 9.78733 60.37 10.7533 60.37 12.0109ZM59.01 12.0457C59.01 11.1301 58.7614 10.4281 58.2642 9.93853C57.7669 9.45013 57.0408 9.20533 56.0845 9.20533C55.6783 9.20533 55.3325 9.23173 55.0457 9.28693V15.1537C55.2045 15.1777 55.4949 15.1885 55.9171 15.1885C56.9042 15.1885 57.666 14.9209 58.2026 14.3857C58.7393 13.8505 59.01 13.0705 59.01 12.0457Z"
                        fill="white"
                      />
                      <path
                        d="M67.5809 13.2446C67.5809 14.1146 67.3261 14.8274 66.8166 15.3866C66.2824 15.9614 65.5747 16.2482 64.6911 16.2482C63.8394 16.2482 63.1612 15.9734 62.6554 15.4214C62.1507 14.8706 61.8984 14.1758 61.8984 13.3382C61.8984 12.4622 62.1581 11.7434 62.68 11.1854C63.2018 10.6274 63.9034 10.3478 64.7871 10.3478C65.6387 10.3478 66.3231 10.6226 66.8412 11.1734C67.3335 11.7086 67.5809 12.3998 67.5809 13.2446ZM66.2431 13.2854C66.2431 12.7634 66.1274 12.3158 65.8972 11.9426C65.6264 11.4914 65.2412 11.2658 64.7403 11.2658C64.2221 11.2658 63.8283 11.4914 63.5575 11.9426C63.3261 12.3158 63.2117 12.7706 63.2117 13.3082C63.2117 13.8302 63.3274 14.2778 63.5575 14.651C63.8369 15.1022 64.2258 15.3278 64.728 15.3278C65.2203 15.3278 65.6067 15.0986 65.8849 14.639C66.1237 14.2586 66.2431 13.8074 66.2431 13.2854Z"
                        fill="white"
                      />
                      <path
                        d="M77.2499 10.4631L75.4345 16.1199H74.253L73.501 13.6635C73.3102 13.0503 73.1551 12.4407 73.0345 11.8359H73.0111C72.8991 12.4575 72.7441 13.0659 72.5447 13.6635L71.7459 16.1199H70.5508L68.8438 10.4631H70.1693L70.8253 13.1523C70.9841 13.7883 71.1145 14.3943 71.2191 14.9679H71.2425C71.3385 14.4951 71.4973 13.8927 71.7213 13.1643L72.5447 10.4643H73.5957L74.3847 13.1067C74.5754 13.7511 74.7305 14.3715 74.8499 14.9691H74.8856C74.973 14.3871 75.1047 13.7667 75.2794 13.1067L75.9834 10.4643H77.2499V10.4631Z"
                        fill="white"
                      />
                      <path
                        d="M83.9371 16.1198H82.6472V12.8798C82.6472 11.8814 82.2583 11.3822 81.478 11.3822C81.0952 11.3822 80.7863 11.519 80.5463 11.7938C80.3088 12.0686 80.1882 12.3926 80.1882 12.7634V16.1186H78.8983V12.0794C78.8983 11.5826 78.8823 11.0438 78.8516 10.4606H79.9851L80.0454 11.345H80.0811C80.2313 11.0702 80.4553 10.8434 80.7494 10.6622C81.0989 10.451 81.4903 10.3442 81.9186 10.3442C82.4602 10.3442 82.9106 10.5146 83.2688 10.8566C83.7143 11.2754 83.9371 11.9006 83.9371 12.731V16.1198Z"
                        fill="white"
                      />
                      <path
                        d="M87.4937 16.1198H86.2051V7.86743H87.4937V16.1198Z"
                        fill="white"
                      />
                      <path
                        d="M95.0868 13.2447C95.0868 14.1147 94.832 14.8275 94.3225 15.3867C93.7883 15.9615 93.0794 16.2483 92.1969 16.2483C91.344 16.2483 90.6658 15.9735 90.1612 15.4215C89.6566 14.8707 89.4043 14.1759 89.4043 13.3383C89.4043 12.4623 89.664 11.7435 90.1858 11.1855C90.7077 10.6275 91.4092 10.3479 92.2917 10.3479C93.1446 10.3479 93.8277 10.6227 94.3471 11.1735C94.8394 11.7087 95.0868 12.3999 95.0868 13.2447ZM93.7477 13.2855C93.7477 12.7635 93.632 12.3159 93.4018 11.9427C93.1323 11.4915 92.7458 11.2659 92.2461 11.2659C91.7268 11.2659 91.3329 11.4915 91.0634 11.9427C90.832 12.3159 90.7175 12.7707 90.7175 13.3083C90.7175 13.8303 90.8332 14.2779 91.0634 14.6511C91.3428 15.1023 91.7317 15.3279 92.2338 15.3279C92.7261 15.3279 93.1114 15.0987 93.3895 14.6391C93.6295 14.2587 93.7477 13.8075 93.7477 13.2855Z"
                        fill="white"
                      />
                      <path
                        d="M101.33 16.1199H100.172L100.076 15.4683H100.041C99.6443 15.9879 99.0794 16.2483 98.3458 16.2483C97.7981 16.2483 97.3551 16.0767 97.0215 15.7359C96.7188 15.4263 96.5674 15.0411 96.5674 14.5839C96.5674 13.8927 96.8628 13.3659 97.4572 13.0011C98.0505 12.6363 98.8849 12.4575 99.9594 12.4659V12.3603C99.9594 11.6151 99.5581 11.2431 98.7545 11.2431C98.1822 11.2431 97.6775 11.3835 97.2418 11.6619L96.9797 10.8363C97.5188 10.5111 98.1846 10.3479 98.9698 10.3479C100.486 10.3479 101.247 11.1279 101.247 12.6879V14.7711C101.247 15.3363 101.275 15.7863 101.33 16.1199ZM99.9914 14.1759V13.3035C98.5686 13.2795 97.8572 13.6599 97.8572 14.4435C97.8572 14.7387 97.9385 14.9595 98.1046 15.1071C98.2708 15.2547 98.4825 15.3279 98.7348 15.3279C99.0178 15.3279 99.2825 15.2403 99.5237 15.0663C99.7661 14.8911 99.9151 14.6691 99.9705 14.3967C99.984 14.3355 99.9914 14.2611 99.9914 14.1759Z"
                        fill="white"
                      />
                      <path
                        d="M108.66 16.1198H107.515L107.455 15.2114H107.419C107.053 15.9026 106.431 16.2482 105.556 16.2482C104.856 16.2482 104.274 15.9806 103.813 15.4454C103.351 14.9102 103.121 14.2154 103.121 13.3622C103.121 12.4466 103.371 11.705 103.873 11.1386C104.359 10.6106 104.955 10.3466 105.664 10.3466C106.443 10.3466 106.988 10.6022 107.298 11.1146H107.323V7.86743H108.614V14.5958C108.614 15.1466 108.629 15.6542 108.66 16.1198ZM107.323 13.7342V12.791C107.323 12.6278 107.311 12.4958 107.287 12.395C107.215 12.0926 107.058 11.8382 106.821 11.633C106.581 11.4278 106.292 11.3246 105.958 11.3246C105.477 11.3246 105.1 11.5106 104.823 11.8838C104.549 12.257 104.41 12.7334 104.41 13.3154C104.41 13.8746 104.541 14.3282 104.806 14.6774C105.085 15.0494 105.462 15.2354 105.933 15.2354C106.357 15.2354 106.695 15.0806 106.952 14.7698C107.201 14.483 107.323 14.1374 107.323 13.7342Z"
                        fill="white"
                      />
                      <path
                        d="M119.691 13.2447C119.691 14.1147 119.436 14.8275 118.927 15.3867C118.393 15.9615 117.686 16.2483 116.801 16.2483C115.951 16.2483 115.273 15.9735 114.766 15.4215C114.261 14.8707 114.009 14.1759 114.009 13.3383C114.009 12.4623 114.268 11.7435 114.79 11.1855C115.312 10.6275 116.014 10.3479 116.899 10.3479C117.749 10.3479 118.435 10.6227 118.952 11.1735C119.444 11.7087 119.691 12.3999 119.691 13.2447ZM118.355 13.2855C118.355 12.7635 118.239 12.3159 118.009 11.9427C117.737 11.4915 117.353 11.2659 116.851 11.2659C116.334 11.2659 115.94 11.4915 115.668 11.9427C115.436 12.3159 115.322 12.7707 115.322 13.3083C115.322 13.8303 115.438 14.2779 115.668 14.6511C115.947 15.1023 116.336 15.3279 116.838 15.3279C117.331 15.3279 117.718 15.0987 117.996 14.6391C118.234 14.2587 118.355 13.8075 118.355 13.2855Z"
                        fill="white"
                      />
                      <path
                        d="M126.626 16.1198H125.338V12.8798C125.338 11.8814 124.949 11.3822 124.167 11.3822C123.784 11.3822 123.476 11.519 123.237 11.7938C122.998 12.0686 122.879 12.3926 122.879 12.7634V16.1186H121.588V12.0794C121.588 11.5826 121.573 11.0438 121.542 10.4606H122.674L122.735 11.345H122.77C122.922 11.0702 123.146 10.8434 123.439 10.6622C123.789 10.451 124.18 10.3442 124.609 10.3442C125.149 10.3442 125.6 10.5146 125.958 10.8566C126.405 11.2754 126.626 11.9006 126.626 12.731V16.1198Z"
                        fill="white"
                      />
                      <path
                        d="M135.307 11.405H133.887V14.153C133.887 14.8514 134.139 15.2006 134.639 15.2006C134.87 15.2006 135.062 15.1814 135.213 15.1418L135.247 16.0958C134.992 16.1894 134.657 16.2362 134.245 16.2362C133.735 16.2362 133.339 16.085 133.052 15.7826C132.764 15.4802 132.621 14.9714 132.621 14.2574V11.405H131.773V10.463H132.621V9.4262L133.885 9.0542V10.4618H135.306V11.405H135.307Z"
                        fill="white"
                      />
                      <path
                        d="M142.135 16.1198H140.844V12.9038C140.844 11.8898 140.455 11.3822 139.676 11.3822C139.078 11.3822 138.67 11.6762 138.446 12.2642C138.407 12.3878 138.385 12.539 138.385 12.7166V16.1186H137.097V7.86743H138.385V11.2766H138.41C138.816 10.6562 139.398 10.3466 140.153 10.3466C140.687 10.3466 141.129 10.517 141.479 10.859C141.916 11.285 142.135 11.9186 142.135 12.7562V16.1198Z"
                        fill="white"
                      />
                      <path
                        d="M149.179 13.0237C149.179 13.2493 149.161 13.4389 149.131 13.5937H145.262C145.28 14.1529 145.464 14.5789 145.822 14.8741C146.15 15.1381 146.572 15.2701 147.089 15.2701C147.661 15.2701 148.183 15.1813 148.653 15.0025L148.855 15.8761C148.305 16.1089 147.657 16.2253 146.908 16.2253C146.009 16.2253 145.302 15.9673 144.79 15.4513C144.275 14.9353 144.021 14.2429 144.021 13.3741C144.021 12.5209 144.258 11.8105 144.737 11.2441C145.237 10.6393 145.912 10.3369 146.765 10.3369C147.6 10.3369 148.233 10.6393 148.662 11.2441C149.008 11.7241 149.179 12.3181 149.179 13.0237ZM147.948 12.6985C147.958 12.3253 147.873 12.0037 147.698 11.7325C147.474 11.3833 147.133 11.2081 146.672 11.2081C146.251 11.2081 145.907 11.3785 145.645 11.7205C145.431 11.9929 145.304 12.3181 145.262 12.6985H147.948Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                </li>
                <li className="me-sm-1 me-0 mb-sm-0 mb-1">
                  <a
                    href="https://play.google.com/store/apps/details?id=ch.pinkswan.lmc"
                    target="_blank"
                    aria-label="Google play store - LMC app"
                    rel="noreferrer"
                  >
                    <img
                      alt="Get it on Google Play"
                      src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                      width={147}
                      height={58}
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="lg:col-span-8 md:col-span-7 col-span-12">
          <div className="w-full p-6 bg-gray-100 flex-col justify-start items-center inline-flex">
            {!isInitiating && !hasLmc && !selectedLmcForCreation && (
              <React.Fragment>
                <div className="self-stretch grow shrink basis-0 pt-[52px] pb-[84px] rounded-lg border border-gray-300 flex-col justify-center items-center gap-6 flex">
                  <div className="self-stretch min-h-[295px] p-4 flex-col justify-start items-center gap-6 flex">
                    <img
                      src="https://cdn.pinkswan.ch/application/UMD/24072013092822_LMC_empty_page.svg"
                      alt="no-lmc"
                      className="grayscale h-full"
                    />
                    <div className="flex-col justify-center items-center gap-1 flex">
                      <div className="text-center text-gray-700 text-2xl font-bold font-['Figtree'] leading-loose">
                        {t("dashboard.features.lmc.page.key6")}
                      </div>
                      <div className="text-center text-zinc-500 text-base font-normal font-['Figtree'] leading-normal">
                        {t("dashboard.features.lmc.page.key7")}
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}

            {currentPlaygroundData &&
              selectedLmcForCreation &&
              renderProperUi[selectedLmcForCreation]}
          </div>
        </div>
      </div>
    );

  return (
    <div className="h-[calc(100vh-65px)]">
      <Helmet>
        <title>Loyalty programs | Super User Manager</title>
        <meta
          name="description"
          content="Register to the easiest and most secure way to manage your users."
        />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_HOST_URL}/control-panel/lmc`}
        />
      </Helmet>

      {content}

      {/* Choose lmc type modal */}
      <Modal size={"7xl"} show={showModal} onClose={() => setShowModal(false)}>
        <Modal.Header className="bg-gray-100 border-gray-100">
          <div className="justify-start items-center gap-2 flex">
            <div className="text-gray-500 text-sm font-semibold font-['Figtree'] leading-tight">
              {t("dashboard.features.lmc.page.key1")}
            </div>
            <HiChevronRight className="text-gray-300" />
            <div className="text-gray-900 text-sm font-semibold font-['Figtree'] leading-tight">
              {t("dashboard.features.lmc.page.key3")}
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="bg-gray-100 pt-0">
          <div className="text-gray-900 text-2xl font-bold font-['Figtree'] leading-loose">
            {t("dashboard.features.lmc.page.key8")}
          </div>
          <div className="text-gray-900 text-sm font-normal font-['Figtree'] leading-tight">
            {t("dashboard.features.lmc.page.key9")}
          </div>

          <div className="grid grid-cols-12 gap-5 mt-8">
            {lmcAvailableTypes.map((lmc, index) => (
              <div
                className="lg:col-span-3 md:col-span-6 col-span-12 flex"
                key={index}
              >
                <div
                  className={`px-4 pt-4 pb-6 bg-white rounded-lg border-2${
                    selectedLmcForCreation === lmc.value
                      ? " border-sky-500"
                      : " "
                  } flex-col justify-start items-start gap-1 flex flex-1 cursor-pointer`}
                  onClick={() => {
                    setSelectedLmcForCreation(lmc.value);
                    setShowModal(false);
                    setEditMode(true);
                  }}
                >
                  {lmc.icon}
                  <h5 className="text-gray-900 text-lg font-semibold font-['Figtree'] leading-7">
                    {lmc.label}
                  </h5>
                  <p className="text-gray-900 text-sm font-normal font-['Figtree'] leading-tight">
                    {lmc.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LmcPage;
