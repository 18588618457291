import { useTranslation } from "react-i18next";

const PageNotWorking = () => {
  const [t] = useTranslation();

  return (
    <div className="w-100 vh-100 bg-light d-flex justify-content-center align-items-center">
      <div className="container mx-auto not-working-page d-flex flex-wrap justify-content-center align-items-center gap-4">
        <img
          src="https://cdn.pinkswan.ch/application/UMD/23070305535988_not-working-page.svg"
          alt="Not Working"
        />
        <div className="d-flex flex-column justify-content-start align-items-start gap-2">
          <h1>{t("dashboard.features.loginTemplate.pageNotWorking.header")}</h1>
          <h5>
            {t(
              "dashboard.features.loginTemplate.pageNotWorking.description-01"
            )}
          </h5>
        </div>
      </div>
    </div>
  );
};

export default PageNotWorking;
