import React, { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { FieldErrors, useForm, UseFormRegister } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import * as Joi from "joi";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import HttpService from "../../services/http";
import { AxiosError } from "axios";
import useAuth from "../../hooks/useAuth";
import { toast } from "react-toastify";
import { Timer } from "./ForgotPassword";
import Plans from "../../components/Plans";
import { Helmet } from "react-helmet-async";
import useAppLocalStorage from "../../hooks/useAppLocalStorage";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { Button, Modal } from "flowbite-react";
import { AiOutlineLoading } from "react-icons/ai";
import { HiArrowLeft, HiEye, HiEyeOff } from "react-icons/hi";
import { BiCheck, BiX } from "react-icons/bi";
import PasswordValidator from "../../components/public/PasswordValidator";
import SocialAuth from "../../components/pages/lmcUserVIew/SocialAuth";
import { IResolveParams } from "reactjs-social-login";

const CountryOptions = () => {
  return (
    <React.Fragment>
      <option value="AF">Afghanistan</option>
      <option value="AX">Aland Islands</option>
      <option value="AL">Albania</option>
      <option value="DZ">Algeria</option>
      <option value="AS">American Samoa</option>
      <option value="AD">Andorra</option>
      <option value="AO">Angola</option>
      <option value="AI">Anguilla</option>
      <option value="AQ">Antarctica</option>
      <option value="AG">Antigua And Barbuda</option>
      <option value="AR">Argentina</option>
      <option value="AM">Armenia</option>
      <option value="AW">Aruba</option>
      <option value="AU">Australia</option>
      <option value="AT">Austria</option>
      <option value="AZ">Azerbaijan</option>
      <option value="BS">Bahamas</option>
      <option value="BH">Bahrain</option>
      <option value="BD">Bangladesh</option>
      <option value="BB">Barbados</option>
      <option value="BY">Belarus</option>
      <option value="BE">Belgium</option>
      <option value="BZ">Belize</option>
      <option value="BJ">Benin</option>
      <option value="BM">Bermuda</option>
      <option value="BT">Bhutan</option>
      <option value="BO">Bolivia</option>
      <option value="BA">Bosnia And Herzegovina</option>
      <option value="BW">Botswana</option>
      <option value="BV">Bouvet Island</option>
      <option value="BR">Brazil</option>
      <option value="IO">British Indian Ocean Territory</option>
      <option value="BN">Brunei Darussalam</option>
      <option value="BG">Bulgaria</option>
      <option value="BF">Burkina Faso</option>
      <option value="BI">Burundi</option>
      <option value="KH">Cambodia</option>
      <option value="CM">Cameroon</option>
      <option value="CA">Canada</option>
      <option value="CV">Cape Verde</option>
      <option value="KY">Cayman Islands</option>
      <option value="CF">Central African Republic</option>
      <option value="TD">Chad</option>
      <option value="CL">Chile</option>
      <option value="CN">China</option>
      <option value="CX">Christmas Island</option>
      <option value="CC">Cocos (Keeling) Islands</option>
      <option value="CO">Colombia</option>
      <option value="KM">Comoros</option>
      <option value="CG">Congo</option>
      <option value="CD">Congo, Democratic Republic</option>
      <option value="CK">Cook Islands</option>
      <option value="CR">Costa Rica</option>
      <option value="CI">Cote DIvoire</option>
      <option value="HR">Croatia</option>
      <option value="CU">Cuba</option>
      <option value="CY">Cyprus</option>
      <option value="CZ">Czech Republic</option>
      <option value="DK">Denmark</option>
      <option value="DJ">Djibouti</option>
      <option value="DM">Dominica</option>
      <option value="DO">Dominican Republic</option>
      <option value="EC">Ecuador</option>
      <option value="EG">Egypt</option>
      <option value="SV">El Salvador</option>
      <option value="GQ">Equatorial Guinea</option>
      <option value="ER">Eritrea</option>
      <option value="EE">Estonia</option>
      <option value="ET">Ethiopia</option>
      <option value="FK">Falkland Islands (Malvinas)</option>
      <option value="FO">Faroe Islands</option>
      <option value="FJ">Fiji</option>
      <option value="FI">Finland</option>
      <option value="FR">France</option>
      <option value="GF">French Guiana</option>
      <option value="PF">French Polynesia</option>
      <option value="TF">French Southern Territories</option>
      <option value="GA">Gabon</option>
      <option value="GM">Gambia</option>
      <option value="GE">Georgia</option>
      <option value="DE">Germany</option>
      <option value="GH">Ghana</option>
      <option value="GI">Gibraltar</option>
      <option value="GR">Greece</option>
      <option value="GL">Greenland</option>
      <option value="GD">Grenada</option>
      <option value="GP">Guadeloupe</option>
      <option value="GU">Guam</option>
      <option value="GT">Guatemala</option>
      <option value="GG">Guernsey</option>
      <option value="GN">Guinea</option>
      <option value="GW">Guinea-Bissau</option>
      <option value="GY">Guyana</option>
      <option value="HT">Haiti</option>
      <option value="HM">Heard Island & Mcdonald Islands</option>
      <option value="VA">Holy See (Vatican City State)</option>
      <option value="HN">Honduras</option>
      <option value="HK">Hong Kong</option>
      <option value="HU">Hungary</option>
      <option value="IS">Iceland</option>
      <option value="IN">India</option>
      <option value="ID">Indonesia</option>
      <option value="IR">Iran, Islamic Republic Of</option>
      <option value="IQ">Iraq</option>
      <option value="IE">Ireland</option>
      <option value="IM">Isle Of Man</option>
      <option value="IL">Israel</option>
      <option value="IT">Italy</option>
      <option value="JM">Jamaica</option>
      <option value="JP">Japan</option>
      <option value="JE">Jersey</option>
      <option value="JO">Jordan</option>
      <option value="KZ">Kazakhstan</option>
      <option value="KE">Kenya</option>
      <option value="KI">Kiribati</option>
      <option value="KR">Korea</option>
      <option value="KP">North Korea</option>
      <option value="KW">Kuwait</option>
      <option value="KG">Kyrgyzstan</option>
      <option value="LA">Lao Peoples Democratic Republic</option>
      <option value="LV">Latvia</option>
      <option value="LB">Lebanon</option>
      <option value="LS">Lesotho</option>
      <option value="LR">Liberia</option>
      <option value="LY">Libyan Arab Jamahiriya</option>
      <option value="LI">Liechtenstein</option>
      <option value="LT">Lithuania</option>
      <option value="LU">Luxembourg</option>
      <option value="MO">Macao</option>
      <option value="MK">Macedonia</option>
      <option value="MG">Madagascar</option>
      <option value="MW">Malawi</option>
      <option value="MY">Malaysia</option>
      <option value="MV">Maldives</option>
      <option value="ML">Mali</option>
      <option value="MT">Malta</option>
      <option value="MH">Marshall Islands</option>
      <option value="MQ">Martinique</option>
      <option value="MR">Mauritania</option>
      <option value="MU">Mauritius</option>
      <option value="YT">Mayotte</option>
      <option value="MX">Mexico</option>
      <option value="FM">Micronesia, Federated States Of</option>
      <option value="MD">Moldova</option>
      <option value="MC">Monaco</option>
      <option value="MN">Mongolia</option>
      <option value="ME">Montenegro</option>
      <option value="MS">Montserrat</option>
      <option value="MA">Morocco</option>
      <option value="MZ">Mozambique</option>
      <option value="MM">Myanmar</option>
      <option value="NA">Namibia</option>
      <option value="NR">Nauru</option>
      <option value="NP">Nepal</option>
      <option value="NL">Netherlands</option>
      <option value="AN">Netherlands Antilles</option>
      <option value="NC">New Caledonia</option>
      <option value="NZ">New Zealand</option>
      <option value="NI">Nicaragua</option>
      <option value="NE">Niger</option>
      <option value="NG">Nigeria</option>
      <option value="NU">Niue</option>
      <option value="NF">Norfolk Island</option>
      <option value="MP">Northern Mariana Islands</option>
      <option value="NO">Norway</option>
      <option value="OM">Oman</option>
      <option value="PK">Pakistan</option>
      <option value="PW">Palau</option>
      <option value="PS">Palestinian Territory, Occupied</option>
      <option value="PA">Panama</option>
      <option value="PG">Papua New Guinea</option>
      <option value="PY">Paraguay</option>
      <option value="PE">Peru</option>
      <option value="PH">Philippines</option>
      <option value="PN">Pitcairn</option>
      <option value="PL">Poland</option>
      <option value="PT">Portugal</option>
      <option value="PR">Puerto Rico</option>
      <option value="QA">Qatar</option>
      <option value="RE">Reunion</option>
      <option value="RO">Romania</option>
      <option value="RU">Russian Federation</option>
      <option value="RW">Rwanda</option>
      <option value="BL">Saint Barthelemy</option>
      <option value="SH">Saint Helena</option>
      <option value="KN">Saint Kitts And Nevis</option>
      <option value="LC">Saint Lucia</option>
      <option value="MF">Saint Martin</option>
      <option value="PM">Saint Pierre And Miquelon</option>
      <option value="VC">Saint Vincent And Grenadines</option>
      <option value="WS">Samoa</option>
      <option value="SM">San Marino</option>
      <option value="ST">Sao Tome And Principe</option>
      <option value="SA">Saudi Arabia</option>
      <option value="SN">Senegal</option>
      <option value="RS">Serbia</option>
      <option value="SC">Seychelles</option>
      <option value="SL">Sierra Leone</option>
      <option value="SG">Singapore</option>
      <option value="SK">Slovakia</option>
      <option value="SI">Slovenia</option>
      <option value="SB">Solomon Islands</option>
      <option value="SO">Somalia</option>
      <option value="ZA">South Africa</option>
      <option value="GS">South Georgia And Sandwich Isl.</option>
      <option value="ES">Spain</option>
      <option value="LK">Sri Lanka</option>
      <option value="SD">Sudan</option>
      <option value="SR">Suriname</option>
      <option value="SJ">Svalbard And Jan Mayen</option>
      <option value="SZ">Swaziland</option>
      <option value="SE">Sweden</option>
      <option value="CH">Switzerland</option>
      <option value="SY">Syrian Arab Republic</option>
      <option value="TW">Taiwan</option>
      <option value="TJ">Tajikistan</option>
      <option value="TZ">Tanzania</option>
      <option value="TH">Thailand</option>
      <option value="TL">Timor-Leste</option>
      <option value="TG">Togo</option>
      <option value="TK">Tokelau</option>
      <option value="TO">Tonga</option>
      <option value="TT">Trinidad And Tobago</option>
      <option value="TN">Tunisia</option>
      <option value="TR">Turkey</option>
      <option value="TM">Turkmenistan</option>
      <option value="TC">Turks And Caicos Islands</option>
      <option value="TV">Tuvalu</option>
      <option value="UG">Uganda</option>
      <option value="UA">Ukraine</option>
      <option value="AE">United Arab Emirates</option>
      <option value="GB">United Kingdom</option>
      <option value="US">United States</option>
      <option value="UM">United States Outlying Islands</option>
      <option value="UY">Uruguay</option>
      <option value="UZ">Uzbekistan</option>
      <option value="VU">Vanuatu</option>
      <option value="VE">Venezuela</option>
      <option value="VN">Vietnam</option>
      <option value="VG">Virgin Islands, British</option>
      <option value="VI">Virgin Islands, U.S.</option>
      <option value="WF">Wallis And Futuna</option>
      <option value="EH">Western Sahara</option>
      <option value="YE">Yemen</option>
      <option value="ZM">Zambia</option>
      <option value="ZW">Zimbabw</option>
    </React.Fragment>
  );
};

const schema = Joi.object({
  entity_name: Joi.string()
    .trim()
    .min(2)
    .max(30)
    .required()
    .label("Company name"),
  entity_description: Joi.string()
    .trim()
    .max(250)
    .optional()
    .allow(null, "")
    .label("Company description"),
  user_first_name: Joi.string()
    .trim()
    .min(2)
    .max(18)
    .pattern(new RegExp(/^[a-zA-Z\s]*$/))
    .required()
    .label("First name")
    .messages({
      "string.pattern.base":
        "'{{#label}}' Only accepts uppercase and lowercase letters and space",
    }),
  user_last_name: Joi.string()
    .trim()
    .min(2)
    .max(18)
    .pattern(new RegExp(/^[a-zA-Z\s]*$/))
    .required()
    .label("Last name")
    .messages({
      "string.pattern.base":
        "'{{#label}}' Only accepts uppercase and lowercase letters and space",
    }),
  user_email: Joi.string()
    .trim()
    .required()
    .regex(/^[^\s@`!@#$%^&*()=]+@[^\s@`!@#$%^&*()=]+\.[^\s@`!@#$%^&*()=]+$/)
    .label("Email")
    .messages({
      "string.pattern.base": "Must be a valid email address",
    }),
  user_password: Joi.string().trim().required().label("Password"),
  user_country_code: Joi.string().trim().required().label("Country"),
  user_agree_terms: Joi.boolean()
    .invalid(false)
    .required()
    .label("Terms and agreements")
    .messages({
      "any.invalid":
        'Accepting "Terms of Service and Privacy Policy" is required',
    }),
});

type RegisterForm = {
  entity_name: string;
  entity_description: string;
  user_first_name: string;
  user_last_name: string;
  user_email: string;
  user_password: string;
  user_country_code: string;
  user_agree_terms: boolean;
};

const Step1 = ({
  errors,
  mutation,
  apiErrors,
  register,
}: {
  errors: any;
  mutation: UseMutationResult<any, AxiosError<any, any>, void, unknown>;
  apiErrors: any;
  register: UseFormRegister<RegisterForm>;
}) => {
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const [validatorCurrentPassword, setValidatorCurrentPassword] =
    useState<string>("");

  const rules = [
    {
      regex: "^.{8,32}$",
      message: t("ruleValidator.key1", { min: 8, max: 32 }),
    },
  ];

  return (
    <React.Fragment>
      <div
        className={`self-stretch bg-white rounded-md border ${
          Object.keys(errors).length > 0 ? "border-red-500" : "border-gray-300"
        } flex-col justify-start items-start flex`}
      >
        <div className="self-stretch px-[13px] py-[9px] bg-white rounded-tl-md rounded-tr-md shadow justify-start items-center inline-flex">
          <input
            type="email"
            placeholder={t("login.label_1").toString()}
            className="text-gray-700 focus:ring-0 p-0 border-none text-base font-normal font-['Figtree'] leading-normal w-full"
            {...register("user_email")}
          />
        </div>

        <div className="self-stretch px-[13px] py-[9px] bg-white rounded-bl-md rounded-br-md shadow border-t border-gray-300 justify-between items-center inline-flex">
          <input
            type={showPassword ? "text" : "password"}
            className="text-gray-700 focus:ring-0 p-0 border-none text-base font-normal font-['Figtree'] leading-normal w-full"
            placeholder={t("login.label_2").toString()}
            {...register("user_password", {
              onChange(event) {
                setValidatorCurrentPassword(event.target.value);
              },
            })}
          />

          <button
            className="w-5 h-5 relative"
            type="button"
            onClick={() => setShowPassword(!showPassword)}
          >
            {!showPassword ? (
              <HiEye color="#9CA3AF" size={20} />
            ) : (
              <HiEyeOff color="#9CA3AF" size={20} />
            )}
          </button>
        </div>

        {/* Password validator */}
        <PasswordValidator
          className={validatorCurrentPassword.length > 0 ? "p-3" : "hidden"}
          ruleSet={rules}
          passwordValue={validatorCurrentPassword}
          passedRuleClassName="flex items-center text-green-500"
          failedRuleClassName="flex items-center text-red-500"
          passIcon={<BiCheck size={20} />}
          failIcon={<BiX size={20} />}
        />
      </div>

      <div className="self-stretch justify-between items-center inline-flex">
        <div className="justify-start items-center gap-2 flex">
          <input
            type="checkbox"
            className="w-4 h-4 relative bg-white rounded border border-gray-300"
            id="user_agree_terms"
            {...register("user_agree_terms")}
          />
          <label
            className={`${
              !!errors.user_agree_terms || apiErrors.user_agree_terms
                ? "text-red-500"
                : "text-gray-700"
            } text-sm font-normal font-['Figtree'] leading-tight`}
            htmlFor="user_agree_terms"
          >
            {t("register.step1.text_1") as string}{" "}
            <a
              href={process.env.REACT_APP_LANDING_URL + "/en-US/privacy-policy"}
              target="_blank"
              className="text-right text-sky-600 text-sm font-medium font-['Figtree'] leading-tight"
              rel="noreferrer"
            >
              {t("register.step1.text_2")}
            </a>
          </label>
        </div>
      </div>
    </React.Fragment>
  );
};

const Step2 = ({
  errors,
  mutation,
  register,
}: {
  errors: any;
  mutation: UseMutationResult<any, AxiosError<any, any>, void, unknown>;
  register: UseFormRegister<RegisterForm>;
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={`w-full rounded-md border ${
        Object.keys(errors).length > 0 ? "border-red-500" : "border-gray-300"
      } flex-col justify-start items-start inline-flex`}
    >
      <div className="self-stretch justify-start items-start inline-flex">
        <div className="self-stretch px-[13px] py-[9px] bg-white rounded-tl-md shadow justify-start items-center inline-flex w-full">
          <input
            type="text"
            placeholder={t("register.step2.label_1").toString()}
            className="text-gray-700 focus:ring-0 p-0 border-none text-base font-normal font-['Figtree'] leading-normal w-full"
            {...register("user_first_name")}
          />
        </div>
        <div className="self-stretch px-[13px] py-[9px] bg-white rounded-tr-md shadow justify-start items-center inline-flex w-full">
          <input
            type="text"
            placeholder={t("register.step2.label_2").toString()}
            className="text-gray-700 focus:ring-0 p-0 border-none text-base font-normal font-['Figtree'] leading-normal w-full"
            {...register("user_last_name")}
          />
        </div>
      </div>
      <div className="self-stretch px-[13px] py-[9px] bg-white shadow border-t border-gray-300 justify-between items-center inline-flex">
        <select
          className="w-full border-0 focus:ring-0 p-0 text-gray-700 text-base font-normal font-['Figtree'] leading-normal"
          {...register("user_country_code")}
        >
          <option value="">{t("register.step2.label_3")}</option>
          <CountryOptions />
        </select>
      </div>
      <div className="self-stretch px-[13px] py-[9px] bg-white shadow border-t border-gray-300  justify-start items-center inline-flex">
        <input
          type="text"
          placeholder={t("register.step2.label_4").toString()}
          className="text-gray-700 focus:ring-0 p-0 border-none text-base font-normal font-['Figtree'] leading-normal w-full"
          {...register("entity_name")}
        />
      </div>
      <div className="self-stretch px-[13px] py-[9px] bg-white border-t border-gray-300  rounded-bl-md rounded-br-md shadow justify-start items-center inline-flex">
        <input
          type="text"
          placeholder={t("register.step2.label_5").toString()}
          className="text-gray-700 focus:ring-0 p-0 border-none text-base font-normal font-['Figtree'] leading-normal w-full"
          {...register("entity_description")}
        />
      </div>
    </div>
  );
};

const Register = () => {
  const localStorage = useAppLocalStorage();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  let location: any = useLocation();
  const [searchParams] = useSearchParams();
  const auth = useAuth();
  const [cookies] = useCookies(["utm_datas", "_fbp", "_fbc"]);

  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    trigger,
    getValues,
  } = useForm<RegisterForm>({
    resolver: joiResolver(schema),
    defaultValues: {
      entity_name: undefined,
      entity_description: undefined,
      user_first_name: undefined,
      user_last_name: undefined,
      user_email: undefined,
      user_password: undefined,
      user_country_code: undefined,
      user_agree_terms: false,
    },
  });

  let from = location.state?.from?.pathname || "/control-panel";

  useEffect(() => {
    let cleaner = true;

    if (cleaner && auth.user.isAuthenticated) {
      if (searchParams.has("language"))
        navigate(from + `?language=${searchParams.get("language")}`);
      else navigate(from);
    }

    let planInPath = searchParams.get("plan");

    if (cleaner && planInPath)
      localStorage.setItem("_umd_service_client_plan", planInPath);
    reset();

    return () => {
      cleaner = false;
    };
  }, [auth, from, navigate, searchParams, reset, localStorage]);

  const [step, setStep] = useState<1 | 2>(1);
  const [showTimer, setShowTimer] = useState(false);
  const [showConfirmEmailModal, setShowConfirmEmailModal] = useState(false);
  const [userData, setUserData] = useState<any>(null);
  const [showPlansModal, setShowPlansModal] = useState(false);
  const [apiErrors, setApiErrors] = useState<any>({});
  const [isSocialAuthInProgress, setIsSocialAuthInProgress] = useState(false);
  const [socialAuthPayload, setSocialAuthPayload] = useState<{
    access_token: string;
    provider: string;
  }>({
    access_token: "",
    provider: "",
  });
  const [user_email, setUser_email] = useState("");

  const RegisterUserMutation = useMutation<any, AxiosError<any, any>>(
    (data: any) => HttpService.post("/auth/register", data),
    {
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
        window.scrollTo(0, 0);
        if (err.response?.data.code === "RSUSKO010") {
          setError("user_email", {
            type: "required",
            message: err.response.data.message,
          });
          setStep(1);
        }
      },
    }
  );

  const ResendEmailVerification = useMutation<any, AxiosError<any, any>>(
    () =>
      HttpService.post(
        `/auth/${userData.ownerUserIdentification}/validation-link`
      ),
    {
      onSuccess: () => {
        toast.success(t("register.msg"));

        setShowTimer(true);
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);

        setShowTimer(false);
      },
    }
  );

  const LoginUserMutation = useMutation<any, AxiosError<any, any>>(
    (credentials: any) => HttpService.post("/auth/login", credentials),
    {
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const PasswordCheckMutation = useMutation<
    any,
    AxiosError<any, any>,
    { password: string; level?: string | null }
  >((data) => HttpService.post("/auth/check-password", data), {
    onError: (err) => {
      toast.error(err.response?.data?.message || err.message);

      setApiErrors({
        ...apiErrors,
        user_password: err.response?.data?.message,
      });
      setError("user_password", err.response?.data?.message);
    },
  });

  const socialLoginMutation = useMutation<
    any,
    any,
    {
      providerPayload: { access_token: string; provider: string };
      type: string;
      user_first_name?: string;
      user_last_name?: string;
      user_country_code?: string;
      entity_name?: string;
      entity_description?: string;
      user_birth_date?: string;
      user_user_name?: string;
    }
  >((data) => HttpService.post("/auth/social/login/owner", data));

  const handleLogin = () => {
    let data: any = {
      user_email: userData.user_email,
      user_password: userData.user_password,
    };

    LoginUserMutation.mutate(data, {
      onSuccess: (res) => {
        queryClient.invalidateQueries("payment-status");

        queryClient
          .fetchQuery("payment-status", () =>
            HttpService.get(
              `/auth/${res.data.element.userIdentification}/${res.data.element.entityIdentification}/verify-payment`
            )
          )
          .then((value) => {
            if (value?.data?.element?.is_new_user) {
              setShowConfirmEmailModal(false);
              setShowPlansModal(true);
              return;
            } else if (!value?.data?.element?.customer_plans?.length) {
              auth.onOffUserEndedSubscription(true);
            } else {
              auth.onOffUserEndedSubscription(false);
            }

            auth.login({
              ...res.data.element,
              is_trial: value.data.element.is_trial,
              trial_left_days: value.data.element.trial_left_days,
              rememberMe: false,
            });

            navigate(from, { replace: true });
          })
          .catch((err: AxiosError<any, any>) => {
            if (
              err.response?.data &&
              err.response?.data?.message !== "Payment checking"
            )
              toast.error(err.response?.data?.message);
            else if (!err.response) toast.error(err.message);

            if (err.response?.status === 402) {
              setShowConfirmEmailModal(false);
              setShowPlansModal(true);
            }
          });
      },
    });
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <Step1
            errors={errors}
            mutation={RegisterUserMutation}
            apiErrors={apiErrors}
            register={register}
          />
        );
      case 2:
        return (
          <Step2
            errors={errors}
            mutation={RegisterUserMutation}
            register={register}
          />
        );
    }
  };

  const handleVerifyAndGoToStep2 = async () => {
    try {
      const result = await trigger([
        "user_email",
        "user_password",
        "user_agree_terms",
      ]);

      if (result) {
        // const level = localStorage.getItem("_umd_password_level");
        const level = "level2";
        PasswordCheckMutation.mutate(
          {
            password: getValues("user_password"),
            level,
          },
          {
            onSuccess: () => {
              setApiErrors({});

              setStep(2);
            },
          }
        );
      }
    } catch (error) {
      // Validation will trigger itself
    }
  };

  const handleRegisterViaSocial = async () => {
    try {
      if (isSocialAuthInProgress) {
        const result = await trigger([
          "user_first_name",
          "user_last_name",
          "user_country_code",
          "entity_name",
          "entity_description",
        ]);

        if (result) {
          const { user_email, user_password, user_agree_terms, ...rest } =
            getValues();

          let newData: {
            entity_name: string;
            entity_description: string;
            user_first_name: string;
            user_last_name: string;
            user_country_code: string;
            user_utm_data?: Record<string, string>;
          } = { ...rest };

          if (
            window.navigator.cookieEnabled &&
            cookies.utm_datas &&
            cookies.utm_datas !== ""
          ) {
            newData.user_utm_data = cookies.utm_datas;

            if (cookies.utm_datas.fbclid) {
              if (cookies._fbp && cookies._fbp !== "") {
                newData.user_utm_data = {
                  ...newData.user_utm_data,
                  fbp: cookies._fbp,
                };
              }

              if (cookies._fbc && cookies._fbc !== "") {
                newData.user_utm_data = {
                  ...newData.user_utm_data,
                  fbc: cookies._fbc,
                };
              }
            }
          }

          socialLoginMutation.mutate(
            {
              type: "register",
              providerPayload: socialAuthPayload,
              ...newData,
            },
            {
              onSuccess({ data }, variables, context) {
                setUser_email(data.element.userEmail);

                localStorage.setItem(
                  "_umd_oauth_credential",
                  JSON.stringify(socialAuthPayload)
                );

                queryClient.invalidateQueries("payment-status");

                queryClient
                  .fetchQuery("payment-status", () =>
                    HttpService.get(
                      `/auth/${
                        data.element.ownerUserIdentification ??
                        data.element.userIdentification
                      }/${data.element.entityIdentification}/verify-payment`
                    )
                  )
                  .then((value) => {
                    setUserData({
                      ownerUserIdentification:
                        data.element.ownerUserIdentification ??
                        data.element.userIdentification,
                      entityIdentification: data.element.entityIdentification,
                      user_email: data.element.userEmail,
                      user_password: "",
                      is_new_user: true,
                    });

                    if (value?.data?.element?.is_new_user)
                      return setShowPlansModal(true);
                    else if (!value?.data?.element?.customer_plans?.length)
                      auth.onOffUserEndedSubscription(true);
                    else auth.onOffUserEndedSubscription(false);

                    localStorage.removeItem("_umd_oauth_credential");

                    auth.login({
                      ...data.element,
                      userIdentification:
                        data.element.ownerUserIdentification ??
                        data.element.userIdentification,
                      is_trial: value.data.element.is_trial,
                      trial_left_days: value.data.element.trial_left_days,
                      rememberMe: true,
                    });

                    navigate(from, { replace: true });
                  })
                  .catch((err: any) => {
                    if (
                      err.response?.data &&
                      err.response?.data?.message !== "Payment checking"
                    )
                      toast.error(err.response?.data?.message);
                    else if (!err.response) toast.error(err.message);

                    if (err.response?.status === 402) {
                      setShowPlansModal(true);
                    }
                  });
              },
              onError(error, variables, context) {
                toast.error(error.response?.data.message || error.message);

                localStorage.removeItem("_umd_oauth_credential");

                if (error.response?.status === 422) {
                  Object.entries(error.response?.data?.element).forEach(
                    ([key, value]) => {
                      setError(
                        key as
                          | "entity_name"
                          | "entity_description"
                          | "user_first_name"
                          | "user_last_name"
                          | "user_email"
                          | "user_password"
                          | "user_country_code"
                          | "user_agree_terms"
                          | "root"
                          | `root.${string}`,
                        { type: "custom", message: value as string }
                      );
                    }
                  );
                }
              },
            }
          );
        }
      }
    } catch (error) {
      // Validation will trigger itself
    }
  };

  const renderStepButton = () => {
    switch (step) {
      case 1:
      default:
        return (
          <Button
            type="button"
            className="w-full bg-sky-500 font-['Figtree']"
            onClick={handleVerifyAndGoToStep2}
            isProcessing={PasswordCheckMutation.isLoading}
            disabled={PasswordCheckMutation.isLoading}
            processingSpinner={
              <AiOutlineLoading className="h-6 w-6 animate-spin" />
            }
            size={"sm"}
          >
            {t("register.renderStepNextButton.btn_1")}
          </Button>
        );
      case 2:
        return (
          <Button
            type="button"
            className="w-full bg-sky-500 font-['Figtree']"
            isProcessing={
              RegisterUserMutation.isLoading ||
              socialLoginMutation.isLoading ||
              queryClient.getQueryState(["payment-status"])?.isFetching
            }
            disabled={
              RegisterUserMutation.isLoading ||
              socialLoginMutation.isLoading ||
              queryClient.getQueryState(["payment-status"])?.isFetching
            }
            processingSpinner={
              <AiOutlineLoading className="h-6 w-6 animate-spin" />
            }
            onClick={
              isSocialAuthInProgress
                ? handleRegisterViaSocial
                : handleSubmit(onSubmit)
            }
            size={"sm"}
          >
            {t("register.registerBtn")}
          </Button>
        );
    }
  };

  const handleLoginStartSocialAuth = () => {};

  const handleResolveSocialAuth = (
    provider: IResolveParams["provider"],
    data: IResolveParams["data"]
  ) => {
    const cpPayload = {
      access_token:
        provider === "google"
          ? data?.access_token
          : data?.authorization?.id_token,
      provider,
    };

    socialLoginMutation.mutate(
      {
        type: "register",
        providerPayload: cpPayload,
      },
      {
        onSuccess(res, variables, context) {
          setUser_email(res.data.element.userEmail);

          localStorage.setItem(
            "_umd_oauth_credential",
            JSON.stringify(cpPayload)
          );

          if (res.status === 200) {
            queryClient.invalidateQueries("payment-status");

            queryClient
              .fetchQuery("payment-status", () =>
                HttpService.get(
                  `/auth/${
                    res.data.element.ownerUserIdentification ??
                    res.data.element.userIdentification
                  }/${res.data.element.entityIdentification}/verify-payment`
                )
              )
              .then((value) => {
                setUserData({
                  ownerUserIdentification:
                    res.data.element.ownerUserIdentification ??
                    res.data.element.userIdentification,
                  entityIdentification: res.data.element.entityIdentification,
                  user_email: res.data.element.userEmail,
                  user_password: "",
                  is_new_user: true,
                });

                if (value?.data?.element?.is_new_user)
                  return setShowPlansModal(true);
                else if (!value?.data?.element?.customer_plans?.length)
                  auth.onOffUserEndedSubscription(true);
                else auth.onOffUserEndedSubscription(false);

                localStorage.removeItem("_umd_oauth_credential");

                auth.login({
                  ...res.data.element,
                  userIdentification:
                    res.data.element.ownerUserIdentification ??
                    res.data.element.userIdentification,
                  is_trial: value.data.element.is_trial,
                  trial_left_days: value.data.element.trial_left_days,
                  rememberMe: true,
                });

                navigate(from, { replace: true });
              })
              .catch((err: any) => {
                if (
                  err.response?.data &&
                  err.response?.data?.message !== "Payment checking"
                )
                  toast.error(err.response?.data?.message);
                else if (!err.response) toast.error(err.message);

                if (err.response?.status === 402) {
                  setShowPlansModal(true);
                }
              });
          } else if (res.status === 201) {
            setIsSocialAuthInProgress(true);
            setSocialAuthPayload(cpPayload);
            setStep(2);
          } else {
            throw new Error("Unexpected response");
          }
        },
        onError(error, variables, context) {
          localStorage.removeItem("_umd_oauth_credential");

          if (error.response?.status === 422) {
            toast.error(error.response?.data.message || error.message);
            Object.entries(error.response?.data?.element).forEach(
              ([key, value]) => {
                setError(
                  key as
                    | "entity_name"
                    | "entity_description"
                    | "user_first_name"
                    | "user_last_name"
                    | "user_email"
                    | "user_password"
                    | "user_country_code"
                    | "user_agree_terms"
                    | "root"
                    | `root.${string}`,
                  { type: "custom", message: value as string }
                );
              }
            );
          } else {
            setIsSocialAuthInProgress(true);
            setSocialAuthPayload(cpPayload);
            setStep(2);
          }
        },
      }
    );
  };

  const handleRejectSocialAuth = (err: any) => {
    if (err.err.error !== "popup_closed_by_user")
      toast.error("Unexpected error happened. Try again later.");
  };

  const onSubmit = (data: any) => {
    let newData: any = { ...data };

    if (
      window.navigator.cookieEnabled &&
      cookies.utm_datas &&
      cookies.utm_datas !== ""
    ) {
      newData.user_utm_data = cookies.utm_datas;

      if (cookies.utm_datas.fbclid) {
        if (cookies._fbp && cookies._fbp !== "") {
          newData.user_utm_data = {
            ...newData.user_utm_data,
            fbp: cookies._fbp,
          };
        }

        if (cookies._fbc && cookies._fbc !== "") {
          newData.user_utm_data = {
            ...newData.user_utm_data,
            fbc: cookies._fbc,
          };
        }
      }
    }

    RegisterUserMutation.mutate(newData, {
      onSuccess: (res) => {
        setShowConfirmEmailModal(true);
        setUserData({
          ...res.data.element,
          user_email: data.user_email,
          user_password: data.user_password,
          is_new_user: true,
        });

        localStorage.setItem(
          "_umd_credential",
          JSON.stringify({
            user_email: data.user_email,
            user_password: data.user_password,
            rememberMe: data.user_agree_terms,
            is_new_user: true,
          })
        );
      },
    });
  };

  return (
    <React.Fragment>
      {step === 2 && (
        <button
          type="button"
          className="text-gray-500 text-decoration-none px-0 mb-1 absolute top-24 left-5 flex items-center gap-2"
          onClick={() => {
            if (isSocialAuthInProgress) reset();

            setStep(1);
          }}
        >
          <HiArrowLeft size={18} className="text-gray-500" />
          <span className="text-sm">
            {t("register.renderStepNextButton.btn_2")}
          </span>
        </button>
      )}

      <div className="auth-page min-vh-100 d-flex align-items-center">
        <Helmet>
          <title>Sign up | Super User Manager</title>
          <meta
            name="description"
            content="Register to the easiest and most secure way to manage your users."
          />
          <link
            rel="canonical"
            href={`${process.env.REACT_APP_HOST_URL}/register`}
          />
        </Helmet>

        <div className="md:w-[448px] w-full flex-col justify-start items-start inline-flex">
          <div className="self-stretch flex-col justify-start items-center gap-6 flex mb-8">
            <div className="flex-col justify-start items-center gap-2 flex">
              <div className="text-center text-gray-700 text-3xl font-bold font-['Figtree'] leading-9">
                {t("register.title")}
              </div>
              {step === 1 ? (
                <div className="justify-start items-center gap-1 inline-flex">
                  <div className="text-gray-500 text-sm font-normal font-['Figtree'] leading-tight">
                    {t("register.loginNote")}
                  </div>
                  <div className="justify-start items-center flex">
                    <Link
                      to={"/login"}
                      className="text-sky-600 text-sm font-medium font-['Figtree'] leading-tight"
                    >
                      {t("register.loginBtn")}
                    </Link>
                  </div>
                </div>
              ) : (
                <div className="justify-start items-center gap-1 inline-flex">
                  <div className="text-gray-500 text-sm font-normal font-['Figtree'] leading-tight">
                    {t("register.loginNote2")}
                  </div>
                </div>
              )}
            </div>
          </div>

          {step === 1 && (
            <React.Fragment>
              <SocialAuth
                className="w-full"
                providers={["Apple", "Google"]}
                onLoginStartSocialAuthStart={handleLoginStartSocialAuth}
                onResolveSocialAuth={handleResolveSocialAuth}
                onRejectSocialAuth={handleRejectSocialAuth}
                providersIcon={{
                  Apple: (
                    <div className="px-[10px] w-full h-11 p-[9px] bg-white hover:bg-sky-400/5 rounded-md shadow border border-gray-300 flex flex-row justify-center items-center cursor-pointer relative mb-4">
                      <img
                        src="../../images/apple-black.svg"
                        alt="Sign up with Google"
                        className="w-6 h-6 absolute top-[10px] left-[10px]"
                      />
                      <p className="text-gray-700 text-sm font-medium font-['Inter'] leading-tight">
                        {t("register.socialApple")}
                      </p>
                    </div>
                  ),
                  Google: (
                    <div className="px-[10px] w-full h-11 p-[9px] bg-white hover:bg-sky-400/5  rounded-md shadow border border-gray-300 flex flex-row justify-center items-center cursor-pointer relative">
                      <img
                        src="../../images/google.svg"
                        alt="Sign up with Google"
                        className="w-6 h-6 absolute top-[10px] left-[10px]"
                      />
                      <p className="text-gray-700 text-sm font-medium font-['Inter'] leading-tight">
                        {t("register.socialGoogle")}
                      </p>
                    </div>
                  ),
                }}
                redirectUrl={{
                  Apple:
                    (process.env.REACT_APP_HOST_URL as string) + "/register",
                  Google:
                    (process.env.REACT_APP_HOST_URL as string) + "/register",
                }}
              />
              <div className="text-gray-500 text-sm font-extrabold font-['Figtree'] leading-tight text-center w-full my-3">
                {i18n.language === "en-US" ? "OR" : "OU"}
              </div>
            </React.Fragment>
          )}

          <form
            onSubmit={handleSubmit(onSubmit)}
            className="self-stretch flex-col justify-start items-start gap-6 flex"
          >
            {renderStep()}

            {errors && Object.keys(errors).length > 0 && (
              <div>
                {Object.keys(errors).map((key) => (
                  <p key={key} className="text-red-500 text-sm mb-0">
                    {errors[
                      key as keyof FieldErrors<RegisterForm>
                    ]?.message?.toString()}
                  </p>
                ))}
              </div>
            )}

            {RegisterUserMutation?.error?.response?.data?.element &&
              Object.keys(RegisterUserMutation.error?.response?.data?.element)
                .length > 0 && (
                <div>
                  {Object.keys(
                    RegisterUserMutation.error?.response?.data?.element
                  ).map((key) => (
                    <p key={key} className="text-red-500 text-sm mb-0">
                      {RegisterUserMutation.error?.response?.data?.element[key]}
                    </p>
                  ))}
                </div>
              )}

            {renderStepButton()}
          </form>
        </div>

        {/* Confirm Email Modal */}
        <Modal show={showConfirmEmailModal} size="2xl">
          <Modal.Body>
            <div>
              <h2 className="text-gray-700 text-2xl font-bold font-['Figtree'] leading-loose mb-1">
                {t("register.confirmEmailModal.text_1")}
              </h2>
              <p className="text-gray-500 text-base font-normal font-['Figtree'] leading-normal">
                {t("register.confirmEmailModal.text_2")}
              </p>
            </div>

            <div className="flex justify-center my-6">
              <img
                title="Super User Manager"
                loading="lazy"
                src="https://cdn.pinkswan.ch/application/UMD/umd_welcome.svg"
                alt="Super User Manager"
                className="img-fluid"
                width={250}
              />
            </div>

            <p className="text-gray-700 text-base font-normal font-['Figtree'] leading-normal mb-6">
              {t("register.confirmEmailModal.text_3")}{" "}
              {userData?.user_email && (
                <b className="text-gray-700 text-base font-semibold font-['Figtree'] leading-normal">
                  {" "}
                  {userData.user_email}{" "}
                </b>
              )}{" "}
              {t("register.confirmEmailModal.text_4")}
            </p>

            <div className="grid grid-cols-12 gap-3">
              <div className="sm:col-span-6 col-span-12 sm:order-1 order-2">
                <Button
                  color={"light"}
                  onClick={() =>
                    userData.user_email &&
                    ResendEmailVerification.mutate(undefined)
                  }
                  disabled={showTimer}
                  className="w-full"
                  isProcessing={ResendEmailVerification.isLoading}
                >
                  {showTimer ? (
                    <span className="text-muted ms-2">
                      <Timer setShowTimer={setShowTimer} ttl={120} />{" "}
                      {t("register.confirmEmailModal.text_5")}
                    </span>
                  ) : (
                    t("register.confirmEmailModal.btn_2")
                  )}
                </Button>
              </div>
              <div className="sm:col-span-6 col-span-12 sm:order-2 order-1">
                <Button
                  className={"bg-sky-500 w-full"}
                  onClick={handleLogin}
                  disabled={LoginUserMutation.isLoading}
                >
                  {t("register.confirmEmailModal.btn_1")}
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Plans modal */}
        <Modal show={showPlansModal} size="7xl" className="w-full">
          <Modal.Body>
            <Plans
              user_identification={userData?.ownerUserIdentification as string}
              entity_identification={userData?.entityIdentification as string}
              chosenPlan={searchParams.get("plan") as string}
              isInBilling={false}
              user_email={user_email}
            />
          </Modal.Body>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default Register;
