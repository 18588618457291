import React, { useEffect, useRef, useState } from "react";
import { AiOutlineLoading } from "react-icons/ai";
import { NavLink, Link, useNavigate, useLocation } from "react-router-dom";
import DlSearch from "./DlSearch";
import { can, PermissionsMapper } from "../../../providers/AuthProvider";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import HttpService from "../../../services/http";
import useAuth from "../../../hooks/useAuth";
import Plans, { EPlans } from "../../Plans";
import { i18n, TFunction } from "i18next";
import { IUser } from "../../../contexts/types";
import { Alert, Button, Dropdown, Modal } from "flowbite-react";
// import ExpiryAlert from "./ExpiryAlert";
import {
  HiGlobeAlt,
  HiOutlineCog,
  HiOutlineGift,
  HiOutlineHome,
  HiOutlineUsers,
  HiOutlineViewGridAdd,
  HiSupport,
} from "react-icons/hi";
import useAppLocalStorage from "../../../hooks/useAppLocalStorage";

const flagEn = "https://cdn.pinkswan.ch/application/UMD/24041507435389_US.png";
const flagFr =
  "https://cdn.pinkswan.ch/application/UMD/24041507435387_France.png";

type SideParMenuProps = {
  t: TFunction<"translation", undefined, "translation">;
  handleUpgrade: VoidFunction;
  handleHideSidebar: VoidFunction;
  i18n: i18n;
  user: IUser;
  show: boolean;
};

const SideBarMenu = ({
  t,
  handleUpgrade,
  handleHideSidebar,
  user,
  show,
  i18n,
}: SideParMenuProps) => {
  const sideBarRef = useRef<HTMLElement | null>(null);

  const menuLinks = [
    {
      title: t("dashboard.layout.item-01"),
      href: "/control-panel",
      icon: <HiOutlineHome size={22} />,
      isEnd: true,
      isAccessible: true,
    },
    {
      title: t("dashboard.layout.item-02"),
      href: "users",
      icon: <HiOutlineUsers size={22} />,
      isEnd: true,
      isAccessible: true,
    },
    {
      title: t("dashboard.layout.item-03"),
      href: "groups",
      icon: <HiOutlineViewGridAdd size={22} />,
      isEnd: true,
      isAccessible: true,
    },
    {
      title: t("dashboard.layout.item-12"),
      href: "login-template",
      icon: <HiGlobeAlt size={22} />,
      isEnd: true,
      isAccessible: !user.isBasic,
    },
    {
      title: t("dashboard.features.lmc.page.title"),
      href: "lmc",
      icon: <HiOutlineGift size={22} />,
      isEnd: true,
      isAccessible: true,
    },
    {
      title: t("dashboard.layout.item-04"),
      href: "develop",
      icon: <HiOutlineCog size={22} />,
      isEnd: true,
      isAccessible: true,
    },
    // {
    //   title: t("dashboard.layout.item-06"),
    //   href: "messages",
    //   icon: <HiOutlineMail size={22} />,
    //   isEnd: true,
    //   isAccessible:
    //     user.customer_permissions &&
    //     can(user.customer_permissions, PermissionsMapper.expert_support),
    // },
    // {
    //   title: t("dashboard.layout.item-08"),
    //   href: "billing",
    //   icon: <HiCurrencyDollar size={22} />,
    //   isEnd: true,
    //   isAccessible: true,
    // },
  ];

  useEffect(() => {
    const handler = (e: any) => {
      if (sideBarRef.current && !sideBarRef.current.contains(e.target)) {
        handleHideSidebar();
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [handleHideSidebar]);

  useEffect(() => {
    const handleResize = () => {
      handleHideSidebar();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleHideSidebar]);

  useEffect(() => {
    if (sideBarRef.current) {
      const links = sideBarRef.current.querySelectorAll("a");

      links.forEach((link) => {
        link.addEventListener("click", (e) => {
          if (show) handleHideSidebar();
        });
      });
    }
  }, [handleHideSidebar, show]);

  useEffect(() => {
    show
      ? document.body.classList.add("menu-opened")
      : document.body.classList.remove("menu-opened");
  }, [show]);

  return (
    <aside
      ref={sideBarRef}
      className={`pt-5 pb-4 bg-gray-800 flex flex-col justify-start items-start gap-5 lg:w-[320px] md:w-[64px] w-[320px] ${
        show ? "absolute left-0 top-0 bottom-0 z-[2]" : "md:inline-flex hidden"
      }`}
    >
      <div className="self-stretch px-4 flex-col justify-start items-start flex">
        <div className="justify-start items-center gap-3 flex w-full">
          <img
            src={
              "https://cdn.pinkswan.ch/application/UMD/24042216091381_Logo.svg"
            }
            alt="Logo"
            className="lg:w-[85%] w-full shrink-0 lg:inline-flex md:hidden inline-block"
          />

          <img
            src={"/images/logo.png"}
            alt="Logo"
            className="lg:w-[85%] w-full shrink-0 lg:hidden md:inline-flex hidden"
          />

          {show && (
            <button
              className="ml-auto w-10 h-10 p-2 bg-gray-600 bg-opacity-0 rounded-[20px] flex-col justify-center items-center inline-flex"
              type="button"
              onClick={handleHideSidebar}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M6 18L18 6M6 6L18 18"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          )}
        </div>
      </div>

      <div className="self-stretch grow shrink basis-0 px-2 flex-col justify-between items-start flex">
        <div className="self-stretch flex-col justify-start items-start gap-6 flex">
          <div className="self-stretch flex-col justify-start items-start gap-1 flex">
            {menuLinks.map((item, index) => {
              if (item.isAccessible)
                return (
                  <NavLink
                    key={index}
                    to={item.href}
                    end={item.isEnd}
                    className={({ isActive }) =>
                      "self-stretch lg:pl-2 lg:pr-3 md:py-2 py-2 md:pl-1 md:pr-1 pl-2 pr-3 rounded-md lg:justify-start md:justify-center justify-start items-center inline-flex" +
                      (isActive ? " text-white bg-gray-900" : " text-[#D1D5DB]")
                    }
                  >
                    <div className="justify-start items-center lg:gap-3 gap-1 flex lg:flex-row md:flex-col flex-row">
                      <div className="w-6 h-6 relative flex justify-center items-center">
                        {item.icon}
                      </div>
                      <div className="lg:text-base md:text-[10px] text-base font-normal font-['Figtree'] leading-normal lg:text-left text-center">
                        {item.title}
                      </div>
                    </div>
                  </NavLink>
                );
              else return null;
            })}
          </div>
          {user.customer_top_plan !== EPlans.UMD_LARGE_BUSINESS && (
            <div className="self-stretch lg:px-2 flex-col justify-start items-start gap-2.5 flex">
              <button
                className="self-stretch lg:pl-[11px] lg:pr-[13px] py-[9px] bg-gray-700 rounded-md shadow justify-center items-center gap-2 inline-flex lg:flex-row flex-col"
                onClick={handleUpgrade}
              >
                <div className="w-5 h-5 relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.8006 1.04626C12.2169 1.17748 12.5 1.56353 12.5 2V7L16.5 7C16.8729 7 17.2148 7.20745 17.387 7.53819C17.5592 7.86892 17.5331 8.26799 17.3192 8.57346L10.3192 18.5735C10.0689 18.931 9.61564 19.085 9.19936 18.9537C8.78309 18.8225 8.5 18.4365 8.5 18L8.5 13H4.5C4.12713 13 3.78522 12.7925 3.61302 12.4618C3.44083 12.1311 3.46694 11.732 3.68077 11.4265L10.6808 1.42653C10.9311 1.06896 11.3844 0.915038 11.8006 1.04626Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div className="text-white lg:text-sm text-[10px] font-medium font-['Inter'] leading-none">
                  {t("dashboard.layout.item-10")}
                </div>
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Language */}
      {show && (
        <div className="px-2">
          <LanguageChanger
            i18n={i18n}
            className={"inline-flex mt-auto bg-gray-700"}
          />
        </div>
      )}

      <p className="text-center text-xs mt-auto px-3 text-gray-400 lg:inline-flex hidden">
        © {new Date().getFullYear()} - Made with ❤️ by passionate teams -
        Version: {process.env.REACT_APP_VERSION}
      </p>
    </aside>
  );
};

type LanguageChangerProps = {
  i18n: i18n;
  className?: string;
};

export const LanguageChanger = ({ i18n, className }: LanguageChangerProps) => {
  const icons: { [key: string]: string } = {
    en: flagEn,
    "en-US": flagEn,
    "en-CH": flagEn,
    "en-EU": flagEn,
    fr: flagFr,
    "fr-CH": flagFr,
    "fr-FR": flagFr,
  };

  const texts: { [key: string]: string } = {
    "en-US": "English",
    "fr-FR": "Français",
    // cn: "中文",
    // es: "Español",
  };

  const classes = `pl-1 pr-1.5 py-1 rounded-[99px] justify-start items-center gap-0.5 cursor-pointer ${
    className ? className : "bg-white md:flex hidden"
  }`;

  return (
    <div className={classes}>
      <Dropdown
        label=""
        dismissOnClick={true}
        renderTrigger={() => (
          <div className="flex items-center">
            <img
              className="w-5 h-5 rounded-full"
              src={icons[i18n.language]}
              alt={i18n.language}
            />
            <div className="w-4 h-4 relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M7.99864 11.3333L12.6653 6H3.33197L7.99864 11.3333Z"
                  fill="#9CA3AF"
                />
              </svg>
            </div>
          </div>
        )}
      >
        {["en-US", "fr-FR"].map((lng) => (
          <Dropdown.Item
            key={lng}
            onClick={() => {
              i18n.changeLanguage(lng);
            }}
          >
            {texts[lng]}
          </Dropdown.Item>
        ))}
      </Dropdown>
    </div>
  );
};

type AccountDropDownProps = {
  t: TFunction<"translation", undefined, "translation">;
  handleLogout: VoidFunction;
  logo: string;
  alt?: string;
};

const AccountDropDown = ({
  t,
  handleLogout,
  logo,
  alt,
}: AccountDropDownProps) => {
  const { user } = useAuth();

  return (
    <Dropdown
      label=""
      dismissOnClick={true}
      renderTrigger={() => (
        <div className="bg-white rounded-2xl justify-center items-center flex cursor-pointer">
          <img
            className="w-8 h-8 rounded-2xl"
            src={logo}
            alt={alt ?? "Account"}
          />
        </div>
      )}
      className="bg-gray-800 w-56"
    >
      <Dropdown.Item
        as={Link}
        to={"profile"}
        className="self-stretch px-4 py-2 justify-start items-center gap-3 inline-flex bg-gray-800 hover:bg-gray-800 focus:bg-gray-800"
      >
        <div className="w-5 h-5 relative">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M10 9C11.6569 9 13 7.65685 13 6C13 4.34315 11.6569 3 10 3C8.34315 3 7 4.34315 7 6C7 7.65685 8.34315 9 10 9Z"
              fill="#9CA3AF"
            />
            <path
              d="M3 18C3 14.134 6.13401 11 10 11C13.866 11 17 14.134 17 18H3Z"
              fill="#9CA3AF"
            />
          </svg>
        </div>
        <span className="text-gray-100 text-sm font-normal font-['Lexend'] leading-tight">
          {t("dashboard.layout.item-05")}
        </span>
      </Dropdown.Item>
      <Dropdown.Item
        as={Link}
        to={"billing"}
        className="self-stretch px-4 py-2 justify-start items-center gap-3 inline-flex bg-gray-800 hover:bg-gray-800 focus:bg-gray-800"
      >
        <div className="w-5 h-5 relative">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M8.43338 7.41784C8.58818 7.31464 8.77939 7.2224 9 7.15101L9.00001 8.84899C8.77939 8.7776 8.58818 8.68536 8.43338 8.58216C8.06927 8.33942 8 8.1139 8 8C8 7.8861 8.06927 7.66058 8.43338 7.41784Z"
              fill="#9CA3AF"
            />
            <path
              d="M11 12.849L11 11.151C11.2206 11.2224 11.4118 11.3146 11.5666 11.4178C11.9308 11.6606 12 11.8861 12 12C12 12.1139 11.9308 12.3394 11.5666 12.5822C11.4118 12.6854 11.2206 12.7776 11 12.849Z"
              fill="#9CA3AF"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 5C11 4.44772 10.5523 4 10 4C9.44772 4 9 4.44772 9 5V5.09199C8.3784 5.20873 7.80348 5.43407 7.32398 5.75374C6.6023 6.23485 6 7.00933 6 8C6 8.99067 6.6023 9.76515 7.32398 10.2463C7.80348 10.5659 8.37841 10.7913 9.00001 10.908L9.00002 12.8492C8.60902 12.7223 8.31917 12.5319 8.15667 12.3446C7.79471 11.9275 7.16313 11.8827 6.74599 12.2447C6.32885 12.6067 6.28411 13.2382 6.64607 13.6554C7.20855 14.3036 8.05956 14.7308 9 14.9076L9 15C8.99999 15.5523 9.44769 16 9.99998 16C10.5523 16 11 15.5523 11 15L11 14.908C11.6216 14.7913 12.1965 14.5659 12.676 14.2463C13.3977 13.7651 14 12.9907 14 12C14 11.0093 13.3977 10.2348 12.676 9.75373C12.1965 9.43407 11.6216 9.20873 11 9.09199L11 7.15075C11.391 7.27771 11.6808 7.4681 11.8434 7.65538C12.2053 8.07252 12.8369 8.11726 13.254 7.7553C13.6712 7.39335 13.7159 6.76176 13.354 6.34462C12.7915 5.69637 11.9405 5.26915 11 5.09236V5Z"
              fill="#9CA3AF"
            />
          </svg>
        </div>
        <span className="text-gray-100 text-sm font-normal font-['Lexend'] leading-tight">
          {t("dashboard.layout.item-08")}
        </span>
      </Dropdown.Item>

      {user.customer_permissions &&
        can(user.customer_permissions, PermissionsMapper.expert_support) && (
          <Dropdown.Item
            as={Link}
            to={"support"}
            className="self-stretch px-4 py-2 justify-start items-center gap-3 inline-flex bg-gray-800 hover:bg-gray-800 focus:bg-gray-800"
          >
            <div className="w-5 h-5 relative">
              <HiSupport size={22} className="text-gray-400" />
            </div>
            <span className="text-gray-100 text-sm font-normal font-['Lexend'] leading-tight">
              {t("dashboard.layout.item-06")}
            </span>
          </Dropdown.Item>
        )}

      <Dropdown.Divider className="bg-gray-600" />
      <Dropdown.Item
        onClick={handleLogout}
        className="self-stretch px-4 py-2 justify-start items-center gap-3 inline-flex bg-gray-800 hover:bg-gray-800 focus:bg-gray-800"
      >
        <div className="w-5 h-5 relative">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3 3C3.55229 3 4 3.44771 4 4L4 16C4 16.5523 3.55228 17 3 17C2.44771 17 2 16.5523 2 16L2 4C2 3.44771 2.44772 3 3 3ZM10.7071 6.29289C11.0976 6.68342 11.0976 7.31658 10.7071 7.70711L9.41421 9L17 9C17.5523 9 18 9.44771 18 10C18 10.5523 17.5523 11 17 11L9.41421 11L10.7071 12.2929C11.0976 12.6834 11.0976 13.3166 10.7071 13.7071C10.3166 14.0976 9.68342 14.0976 9.29289 13.7071L6.29289 10.7071C6.10536 10.5196 6 10.2652 6 10C6 9.73478 6.10536 9.48043 6.29289 9.29289L9.29289 6.29289C9.68342 5.90237 10.3166 5.90237 10.7071 6.29289Z"
              fill="#9CA3AF"
            />
          </svg>
        </div>
        <span className="text-gray-100 text-sm font-normal font-['Lexend'] leading-tight">
          {t("dashboard.layout.btn-01")}
        </span>
      </Dropdown.Item>
    </Dropdown>
  );
};

const Layout = ({ children }: { children: React.ReactNode }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const localStorage = useAppLocalStorage();

  const {
    customer_identification,
    user,
    setUser,
    onOffUpgradeNow,
    onOffUserEndedSubscription,
  } = useAuth();

  const [error, setError] = useState<any>();
  const [showPlansModal, setShowPlansModal] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false);
  const [showSideMenu, setShowSideMenu] = useState(false);
  const [showLogOutPopUp, setShowLogOutPopUp] = useState(false);
  const [showHomePopUp, setShowHomePopUp] = useState(false);

  const {
    isError,
    isLoading,
    isSuccess,
    data: verifyData,
    refetch,
  } = useQuery(
    "verify-plans",
    () =>
      HttpService.get(
        `/auth/${customer_identification}/${user.entityIdentification}/verify-payment`
      ),
    {
      onSuccess: (value) => {
        if (value?.data?.element?.is_new_user) {
          setShowPlansModal(true);
          setIsNewUser(true);
        } else {
          setIsNewUser(false);
          setUser({
            ...user,
            customer_top_plan: value.data.element.customer_top_plan,
            customer_permissions:
              value.data.element.customer_top_plan_permissions,
            is_trial: value.data.element.is_trial,
            trial_left_days: value.data.element.trial_left_days,
          });
        }

        if (value?.data?.element?.customer_plans?.length <= 0) {
          toast.warn(
            "You have no active plans. Please upgrade your subscription."
          );

          onOffUserEndedSubscription(true);
          setShowPlansModal(true);
        } else {
          onOffUserEndedSubscription(false);
        }
      },
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);

        if (
          err.response?.status === 402 ||
          err.response?.data?.code === "RSPYKO001"
        ) {
          setError(err);
        }
      },
    }
  );

  const { data: res, isSuccess: isPrefSuccess } = useQuery(
    ["entity_preferences", user.entityIdentification],
    () =>
      HttpService.get(
        `/${customer_identification}/${user.entityIdentification}/entity/preferences`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      enabled: isSuccess,
      onError: (err: AxiosError<any, any>) => {
        if (!isNewUser) toast.error(err.response?.data?.message || err.message);
      },
      onSuccess({ data }) {
        localStorage.setItem(
          "_umd_password_level",
          data.element.password_validation_regex_level
        );
      },
    }
  );

  const upgradeNowHandler = () => {
    onOffUpgradeNow(true);
    navigate("billing", { replace: true });
  };

  useEffect(() => {
    if (isSuccess) {
      if (verifyData?.data?.element?.is_new_user && !showPlansModal) {
        toast.warn(
          "You have no active plans. Please upgrade your subscription."
        );

        setShowPlansModal(true);
        setIsNewUser(true);
      }

      if (
        verifyData?.data?.element?.customer_plans?.length <= 0 &&
        !showPlansModal
      ) {
        toast.warn(
          "You have no active plans. Please upgrade your subscription."
        );

        onOffUserEndedSubscription(true);
        setShowPlansModal(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isSuccess,
    location,
    onOffUserEndedSubscription,
    verifyData?.data?.element?.customer_plans?.length,
    verifyData?.data?.element?.is_new_user,
  ]);

  let content;

  if (isLoading)
    content = (
      <div className="container mx-auto py-6">
        Loading...
        <AiOutlineLoading className="h-6 w-6 animate-spin ml-2 inline-flex" />
      </div>
    );

  if (isError)
    content = (
      <div className="container mx-auto py-6">
        {!error ? (
          <Button color="info" onClick={() => refetch()} size="sm">
            Refetch
          </Button>
        ) : (
          <Alert color="warning">
            Your subscription is ended.
            <Button
              color="warning"
              size="sm"
              className="mr-2"
              onClick={() => setShowPlansModal(true)}
            >
              Buy a plan
            </Button>
          </Alert>
        )}
      </div>
    );

  if (isSuccess && isPrefSuccess) content = children;

  return (
    <div className="dashboard-v2">
      <SideBarMenu
        t={t}
        i18n={i18n}
        user={user}
        handleUpgrade={upgradeNowHandler}
        show={showSideMenu}
        handleHideSidebar={() => setShowSideMenu(false)}
      />

      <main
        className="lg:w-[calc(100% - 320px)] w-full bg-gray-100"
        id="content-area"
      >
        <header className="sticky top-0 z-[1]">
          <div className="w-full h-16 px-4 bg-white shadow justify-start items-center gap-6 inline-flex">
            <button
              className="h-full md:px-4 pr-4 justify-center items-center inline-flex border-r border-gray-100 md:hidden"
              onClick={() => setShowSideMenu(true)}
            >
              <div className="w-6 h-6 relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M4 6H20M4 12H20M4 18H11"
                    stroke="#6B7280"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </button>
            <DlSearch />
            <div className="justify-start items-center gap-3 flex shrink-0">
              <LanguageChanger i18n={i18n} />
              <AccountDropDown
                t={t}
                handleLogout={() => setShowLogOutPopUp(true)}
                logo={res?.data?.element?.cdn_logo_project_path}
              />
            </div>
          </div>
        </header>

        {/* {user.is_trial && (
          <ExpiryAlert trial_left_days={user.trial_left_days} />
        )} */}

        {content}
      </main>

      <Modal
        show={showPlansModal}
        onClose={() => setShowPlansModal(false)}
        size="7xl"
      >
        <Modal.Header>{t("dashboard.billings.plansModal.title")}</Modal.Header>
        <Modal.Body>
          <Plans
            user_identification={customer_identification}
            entity_identification={user.entityIdentification}
            hasPremiumPlan={true}
            isInBilling={false}
            onCloseModal={() => setShowPlansModal(false)}
          />
        </Modal.Body>
        <Modal.Footer className="justify-end">
          <Button color="light" onClick={() => setShowPlansModal(false)}>
            {t("dashboard.billings.plansModal.btn-01")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showHomePopUp} onClose={() => setShowHomePopUp(false)}>
        <Modal.Body className="flex flex-col items-center justify-center">
          <img
            title="Super User Manager"
            loading="lazy"
            src={"https://cdn.pinkswan.ch/application/UMD/umd_logout.svg"}
            className="mb-6"
            alt="logout logo"
            width={170}
            height={150}
          />

          <h2 className="text-gray-700 text-2xl leading-none font-bold font-['Figtree'] mb-3">
            You have canceled your trial plan
          </h2>

          <p className="text-gray-500 text-base font-normal font-['Figtree'] leading-normal mb-8">
            Two ways to keep enjoying Super User Manager dashboard:
          </p>

          <div className="grid grid-cols-12 gap-3">
            <div className="sm:col-span-6 col-span-12 sm:order-1 order-2">
              <Button
                as={Link}
                to={"/contact-us"}
                onClick={() => setShowHomePopUp(false)}
                color="light"
              >
                Contact us
              </Button>
            </div>
            <div className="sm:col-span-6 col-span-12 sm:order-1 order-2">
              <Button
                as={Link}
                onClick={() => setShowHomePopUp(false)}
                to={"billing"}
                className="bg-sky-500"
              >
                Upgrade now
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showLogOutPopUp}
        onClose={() => setShowLogOutPopUp(false)}
        position={"center"}
        dismissible
      >
        <Modal.Body>
          <div className="px-6 pt-6 pb-4 sm:justify-start sm:items-start justify-center items-center gap-4 inline-flex sm:flex-row flex-col">
            <div className="w-10 pl-3 pr-2 py-2.5 bg-red-100 rounded-3xl justify-end items-center flex">
              <div className="w-5 h-5 relative flex-col justify-start items-start flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M3 3C2.44772 3 2 3.44772 2 4V16C2 16.5523 2.44772 17 3 17C3.55229 17 4 16.5523 4 16V4C4 3.44772 3.55229 3 3 3Z"
                    fill="#DC2626"
                  />
                  <path
                    d="M13.2929 12.2929C12.9024 12.6834 12.9024 13.3166 13.2929 13.7071C13.6834 14.0976 14.3166 14.0976 14.7071 13.7071L17.7071 10.7071C17.8947 10.5196 18 10.2652 18 10C18 9.73478 17.8947 9.48043 17.7071 9.29289L14.7071 6.29289C14.3166 5.90237 13.6834 5.90237 13.2929 6.29289C12.9024 6.68342 12.9024 7.31658 13.2929 7.70711L14.5858 9L7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H14.5858L13.2929 12.2929Z"
                    fill="#DC2626"
                  />
                </svg>
              </div>
            </div>
            <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex sm:text-left text-center">
              <div className="self-stretch text-gray-900 text-lg font-semibold font-['Figtree'] leading-7">
                {t("dashboard.layout.text-01")}
              </div>
              <div className="self-stretch text-gray-500 text-sm font-normal font-['Figtree'] leading-tight">
                {t("dashboard.layout.text-02")}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="px-6 py-3 bg-gray-50 sm:justify-end justify-center items-center space-x-0 gap-3 inline-flex w-full sm:flex-row flex-col">
          <button
            className="text-gray-700 text-sm font-medium font-['Inter'] leading-tight px-[17px] py-[9px] bg-white rounded-md shadow border border-gray-300 justify-center items-center flex sm:w-auto w-full sm:order-1 order-2"
            onClick={() => setShowLogOutPopUp(false)}
          >
            {t("dashboard.layout.btn-02")}
          </button>
          <Link
            to={"/logout"}
            className="text-white text-sm font-medium font-['Inter'] leading-tight px-[17px] py-[9px] bg-red-600 rounded-md shadow justify-center items-center flex sm:w-auto w-full sm:order-2 order-1"
          >
            {t("dashboard.layout.btn-01")}
          </Link>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Layout;
