import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const ServerError = () => {
  return (
    <>
      <Helmet>
        <title>Super User Manager - Server error</title>
        <meta
          name="description"
          content="The easiest and most secure way to manage your users."
        />
        <meta name="robots" content="noindex" />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_HOST_URL}/server-error`}
        />
      </Helmet>

      <h1 className="audiowide">500</h1>
      <h4 className="prompt">The end is near</h4>
      <img
        title="Super User Manager"
        loading="lazy"
        src="https://cdn.pinkswan.ch/application/UMD/23030509171543_UMD_Error500_2.svg"
        alt="CDN"
        className="img-fluid my-5 d-block mx-auto"
        width={400}
      />
      <Link
        to={"/"}
        className="btn btn-lg btn-outline-primary rounded-pill pink"
      >
        Go home
      </Link>
    </>
  );
};

export default ServerError;
